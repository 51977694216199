<template>
  <div>
    <!-- banner -->
    <div class="banner n1">
      <div class="banner-text w1200">
        <p>智慧园区</p>
        <p class="disription">
          实现园区可视化管理平台，支持可视化、可模拟和可分析的管理模式
        </p>
        <!-- <a href="javascript:">联系我们</a> -->
      </div>
      <div class="banner-mask"></div>
    </div>
    <!-- 总体思路 -->
    <div class="jssl">
      <div class="jssl-main bigbt w1200">
        <div class="left">
          <div class="title">
            <p>总体思路</p>
            <div class="hr"></div>
            <span class="content">
              以“城市空间、建筑空间、地下空间“三位一体的模型为载体，通过数字孪生技术，构筑园区数字化空间底板，结合物联网技术、地理信息技术实现各子系统的设备数据和业务数据集成，构筑以CIM为基础的可视化管理平台，支持可视化、可模拟和可分析的管理模式
            </span>
          </div>
        </div>
        <div class="right">
          <img src="./images/whole-sl.png" alt="" />
        </div>
      </div>
      <div class="jssl-content w1200">
        <div class="content-main">
          <div class="main-img">
            <!-- <img src="./images/szhkj.png" alt=""> -->
            <div class="item-mask"></div>
          </div>
          <div class="main-txt">
            <div class="main-title">数字化空间底板搭建</div>
            <div class="main-text">
              数字空间底板数据包括二维电子地图、卫星影像、白模、实景三维模型与BIM模型，形成从宏观到微观的，从平面到立体、从符号化到具象化的二三维一体化空间支撑体系。
            </div>
          </div>
        </div>
        <div class="content-main">
          <div class="main-img">
            <!-- <img src="./images/guanliyaosu.png" alt=""> -->
            <div class="item-mask"></div>
          </div>
          <div class="main-txt">
            <div class="main-title">管理服务要素空间映射</div>
            <div class="main-text">
              CIM（城市信息模型）以建筑信息模型、地理信息系统、物联网等技术为基础，整合城市地上地下、室内室外、历史现状未来多维多尺度信息模型数据和城市感知数据，构建起三维数字空间的城市信息有机综合体
            </div>
          </div>
        </div>
        <div class="content-main">
          <div class="main-img">
            <!-- <img src="./images/ksh-guanli.png" alt=""> -->
            <div class="item-mask"></div>
          </div>
          <div class="main-txt">
            <div class="main-title">可视化管理平台建设</div>
            <div class="main-text">
              利用数据可视化的方式，让园区内土地利用、建设用地、人口数据、企业数据、建筑数据、道路中心线、公共服务设施等数据以一定的形式规划还原，构建起三维数字空间
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="ztkj bigbt w1200">
      <div class="title">
        <p>总体框架</p>
        <div class="hr"></div>
      </div>
      <div class="content title-top">
        <div class="item first">
          <div class="item-cont">
            <div class="cont-title">视频融合</div>
            <div class="cont-cont">
              融合天网、雪亮工程等能力，绘制园区安防专题图，直观展示视频监控范围，充分调配视频监控设备能力，在三维场景中反映当前园区室内外真实运营情况
            </div>
          </div>
          <div class="item-mask">
            <img src="./images/together.png" />
            <div class="mask"></div>
          </div>
        </div>
        <div class="item zd">
          <div class="item-mask">
            <img src="./images/baojing.png" />
            <div class="mask"></div>
          </div>
          <div class="item-cont">
            <div class="cont-title">入侵报警</div>
            <div class="cont-cont">
              通过数字孪生方式实现可视化设备管理及动态追踪，结合人脸识别及图像识别实现出入口门禁、
              周界安防、电子报警等模块的动态三维可视化监控及追踪，实现三维协同监控
            </div>
          </div>
        </div>
        <div class="item zc">
          <div class="item-cont">
            <div class="cont-title">设备管理</div>
            <div class="cont-cont">
              设备运行情况监测；设备细节数据查看；大型设备模拟进场
            </div>
          </div>
          <div class="item-mask">
            <img src="./images/machine.png" />
            <div class="mask"></div>
          </div>
        </div>
        <div class="item ab">
          <div class="item-mask">
            <img src="./images/zichan.png" />
            <div class="mask"></div>
          </div>
          <div class="item-cont">
            <div class="cont-title">资产管理</div>
            <div class="cont-cont">
              园区资产三维查看;资产未知变更管理;重要资产细节监控
            </div>
          </div>
        </div>
        <div class="item jw">
          <div class="item-cont">
            <div class="cont-title">能源管理</div>
            <div class="cont-cont">
              园区整体、楼宇内、甚至楼层、房间内的耗能情况进行实时监测
            </div>
          </div>
          <div class="item-mask">
            <img src="./images/menergy.png" />
            <div class="mask"></div>
          </div>
        </div>
        <div class="item zafk">
          <div class="item-mask">
            <img src="./images/trip.png" />
            <div class="mask"></div>
          </div>
          <div class="item-cont">
            <div class="cont-title">停车管理</div>
            <div class="cont-cont">
              智慧停车管理系统;交通信息诱导(与交通专业配合);车牌快速识别系统;车位引导系统;智慧停车场
            </div>
          </div>
        </div>

        <div class="item zfgk">
          <div class="item-cont">
            <div class="cont-title">招商管理</div>
            <div class="cont-cont">
              构建园区招商及宣传多源化，通过虚拟现实、实景三维等技术建立园区数字孪生招商平台，构建APP、电脑端、大屏幕端、可视化移动屏幕、宣传广播等多种形式多种媒体方式进行相关信息的传递和展示
            </div>
          </div>
          <div class="item-mask">
            <img src="./images/zs.png" />
            <div class="mask"></div>
          </div>
        </div>
        <div class="item sprh">
          <div class="item-mask">
            <img src="./images/meetingRoom.png" />
            <div class="mask"></div>
          </div>
          <div class="item-cont">
            <div class="cont-title">会议室管理</div>
            <div class="cont-cont">
              智慧停车管理系统;交通信息诱导(与交通专业配合);车牌快速识别系统;车位引导系统;智慧停车场
            </div>
          </div>
        </div>

        <div class="item nopeople">
          <div class="item-cont">
            <div class="cont-title">巡更管理</div>
            <div class="cont-cont">
              监督保安人员定时，定点，定路线进行巡逻，防止保安人员懈怠偷懒，做到技防和人防相结合，可以大大改善园区的治安环境
            </div>
          </div>
          <div class="item-mask">
            <img src="./images/moving-forward.png" />
            <div class="mask"></div>
          </div>
        </div>

        <div class="item VR">
          <div class="item-mask">
            <img src="./images/gongdan.png" />
            <div class="mask"></div>
          </div>
          <div class="item-cont">
            <div class="cont-title">工单管理</div>
            <div class="cont-cont">
              基于空间颗粒维度将故障信息、维护信息嵌入到对应的空间点位，同时关联执行工单，形成可视化的空间管理模式，辅助高效的工单派发与任务执行
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="gank"></div>
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
import './../solution.css';
import Footer from '@/components/Footer.vue';
export default {
  name: 'Zhsw',
  components: {
    Footer
  }
};
</script>

<style lang="less" scoped>
/********************* banner start **************/

.banner {
  background-image: url('./images/banner.png');
  background-size: contain;
  /* background-color: red; */
  position: relative;
}
.banner > .banner-text {
  width: 734px;
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -60%);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.banner > .banner-text > p:first-child {
  font-size: 60px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 35px;
  letter-spacing: 10px;
}
.banner > .banner-text > p.disription {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
}
.banner > .banner-text > p.disription > .line {
  margin: 0 10px 0 9px;
}
.banner > .banner-text > a {
  width: 247px;
  height: 65px;
  border: 2px solid #ffffff;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 65px;
  text-align: center;
  display: block;
  margin-top: 36px;
  border-radius: 6px;
}

/********************* banner end **************/

/********************* 总体思路 start **************/

.jssl {
  border: 1px solid transparent;
  margin-top: 90px;
}
.jssl > .jssl-main {
  display: flex;
  margin-top: 90px;
  justify-content: space-between;
}
.jssl > .jssl-main > .left {
  width: 523px;
}
.jssl > .jssl-main > .right {
  width: 470px;
  height: 353px;
}
.jssl > .jssl-main > .right > img {
  width: 100%;
  height: 100%;
}
.jssl > .jssl-main > .left > .title {
  margin-top: 0px;
}
.jssl > .jssl-main > .left > .title > p {
  text-align: left;
}
.jssl > .jssl-main > .left > .title > .hr {
  margin: 25px 0 60px 0;
}
.jssl > .jssl-main > .left > .title > .content {
  width: 523px;
  height: 128px;
  line-height: 34px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  /* font-weight: bold; */
  color: #333;
}
.jssl-content {
  margin-top: 155px;
  height: 457px;
  display: flex;
  padding-top: 120px;
  justify-content: space-between;
}
.jssl-content > .content-main {
  width: 386px;
  height: 400px;
  background: #ffffff;
  box-shadow: 0px 5px 6px 0px rgba(193, 193, 193, 0.27),
    0px -5px 6px 0px rgba(203, 203, 203, 0.19);
  border-radius: 10px;
  padding-bottom: 58px;
}
.jssl-content > .content-main > .main-img {
  background-size: cover;
  width: 100%;
  height: 231px;
  position: relative;
}
.jssl-content > .content-main:nth-child(1) > .main-img {
  background-image: url('./images/szhkj.png');
}
.jssl-content > .content-main:nth-child(2) > .main-img {
  background-image: url('./images/guanliyaosu.png');
}
.jssl-content > .content-main:nth-child(3) > .main-img {
  background-image: url('./images/kshpingtai.png');
}
.jssl-content > .content-main > .main-img > .item-mask {
  width: 100%;
  height: 231px;
  position: absolute;
  top: 0;
  background: #3065ee;
  opacity: 0.1;
  border-radius: 10px;
}
.jssl-content > .content-main > .main-img > img {
  width: 100%;
  height: 100%;
}
.jssl-content > .content-main > .main-txt {
  padding: 0 26px 0 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.jssl-content > .content-main > .main-txt > .main-title {
  margin: 25px 0 20px 0;
  /* width: 197px; */
  height: 22px;
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
}
.jssl-content > .content-main > .main-txt > .main-text {
  width: 342px;
  /* height: 100px; */
  font-size: 16px;
  line-height: 28px;
  font-family: Source Han Sans CN;
  /* font-weight: 400; */
  color: #333;
}
/********************* 总体思路 end **************/

/********************* 应用场景 start **************/

.ztkj > .content > img {
  display: block;
  height: 510px;
  width: 100%;
}
.ztkj > .content > .item.first {
  margin-top: 198px;
}
.ztkj > .content > .item.last {
  margin-bottom: 0;
}
.ztkj > .content > .item.ab {
  text-align: center;
}

.ztkj > .content > .item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}
.ztkj > .content > .item > .item-mask {
  width: 458px;
  height: 280px;
  position: relative;
}
.ztkj > .content > .item > .item-mask > img {
  width: 100%;
  height: 100%;
  display: block;
}
.ztkj > .content > .item > .item-mask > .mask {
  width: 458px;
  height: 280px;
  position: absolute;
  top: 0;
  background: #3065ee;
  opacity: 0.1;
  border-radius: 10px 10px 0px 0px;
}
.ztkj > .content > .item > .item-cont {
  width: 458px;
  height: 220px;
  padding-top: 60px;
}
.ztkj > .content > .item > .item-cont > .cont-title {
  font-size: 24px;
  font-weight: 600;
  font-family: Source Han Sans CN;
  margin-bottom: 30px;
  text-align: center;
}
.ztkj > .content > .item > .item-cont > .cont-cont {
  font-size: 16px;
  font-family: Source Han Sans CN;
  /* font-weight: bold; */
  color: #333;
}
/********************* 应用场景 end **************/
</style>
