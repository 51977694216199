<template>
  <div class="serviceUpgrade-title">
    <p>{{ title }}</p>
    <p class="serviceUpgrade-title-bg">{{ bgTitle }}</p>
    <p class="serviceUpgrade-title-sub">
      <slot>{{ subTitle }}</slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ServiceUpgradeTitle',
  props: {
    title: String,
    bgTitle: String,
    subTitle: String
  }
};
</script>

<style lang="less" scoped>
.serviceUpgrade-title {
  position: relative;
  color: #333;
  font-size: 30px;
  text-align: center;
  &-bg {
    position: absolute;
    left: 50%;
    top: 12px;
    transform: translateX(-50%);
    color: #0096ff;
    opacity: 0.1;
  }
  &-sub {
    margin-top: 40px;
    color: #252d45;
    font-size: 22px;
  }
}
</style>
