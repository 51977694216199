<template>
  <div>
    <!-- banner -->
    <div class="banner n1">
      <div class="banner-text w1200">
        <p>智慧警务</p>
        <p class="disription">新时代智慧警务应用</p>
        <!-- <a href="javascript:">联系我们</a> -->
      </div>
      <div class="banner-mask"></div>
    </div>
    <!-- 建设思路 -->
    <div class="jssl">
      <div class="jssl-main bigbt w1200">
        <div class="left">
          <div class="title">
            <p>建设思路</p>
            <div class="hr"></div>
            <span class="content">
              通过不同的颗粒度（宏观到微观、室外到室内、地上到地下）对空间进行三维刻画，可让使用者以顶层视角感知空间（区域）、物体（设备）、行为（活动）。可为警务治安防控、指挥调度、侦查破案、安防布控、反恐推演、重大事件保障等业务场景提供可视化、可量算、可感知的支撑底板。
            </span>
          </div>
        </div>
        <div class="right">
          <img src="./images/bujianji-sanwei.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 总体框架 -->
    <div class="ztkj bigbt w1200">
      <div class="title">
        <p>总体框架</p>
        <div class="hr"></div>
      </div>
      <div class="content title-top">
        <img src="./images/whole.png" alt="" />
        <div class="item first">
          <div class="item-cont">
            <div class="cont-title">一标六实管理应用</div>
            <div class="cont-cont">
              实有房屋、实有人口、实有组织、安防设施、力量设备、警情分散
            </div>
          </div>
          <div class="item-img">
            <img src="./images/one-six.png" alt="" />
            <div class="item-mask"></div>
          </div>
        </div>
        <div class="item zd">
          <div class="item-img">
            <img src="./images/zdrenyuan.png" alt="" />
            <div class="item-mask"></div>
          </div>
          <!-- <img src="./images/zdrenyuan.png" alt="" class="item-img"> -->
          <div class="item-cont">
            <div class="cont-title">重点人员跟踪应用</div>
            <div class="cont-cont">
              产生预警，层级上报，目标时空跟随;建立布控人员库多维布控
            </div>
          </div>
        </div>
        <div class="item zc">
          <div class="item-cont">
            <div class="cont-title">侦察与指挥应用</div>
            <div class="cont-cont">
              作案或逃逸路径测量与分析;资源立体布控;侦察信息空间分析
            </div>
          </div>
          <div class="item-img">
            <img src="./images/zcyzh.png" alt="" />
            <div class="item-mask"></div>
          </div>
          <!-- <img src="./images/zcyzh.png" alt="" class="item-img"> -->
        </div>
        <div class="item ab">
          <div class="item-img">
            <img src="./images/zdab.png" alt="" />
            <div class="item-mask"></div>
          </div>
          <!-- <img src="./images/zdab.png" alt="" class="item-img"> -->
          <div class="item-cont">
            <div class="cont-title">重大安保预演应用</div>
            <div class="cont-cont">资源调度;预案制作;预案评估</div>
          </div>
        </div>
        <div class="item jw">
          <div class="item-cont">
            <div class="cont-title">警情处理应用</div>
            <div class="cont-cont">
              智能报警提示;智能任务指派;高精度报警定位;精细化出警规划;区域警情评估;案件查询与分析
            </div>
          </div>
          <div class="item-img">
            <img src="./images/jingqingchuliyingyong.png" alt="" />
            <div class="item-mask"></div>
          </div>
          <!-- <img src="./images/jingqingchuliyingyong.png" alt="" class="item-img"> -->
        </div>
        <div class="item zafk">
          <div class="item-img">
            <img src="./images/zafk.png" alt="" />
            <div class="item-mask"></div>
          </div>
          <!-- <img src="./images/zafk.png" alt="" class="item-img"> -->
          <div class="item-cont">
            <div class="cont-title">治安防控应用</div>
            <div class="cont-cont">
              人员非法聚集报警;精细化治安巡逻;电子围栏式重点场所管理;立体网络防控与治理
            </div>
          </div>
        </div>

        <div class="item zfgk">
          <div class="item-cont">
            <div class="cont-title">执法管控应用</div>
            <div class="cont-cont">
              让执法事故"零发生",有力提升公安行业机关的执法形象和执法公信力
            </div>
          </div>
          <div class="item-img">
            <img src="./images/zfgk.png" alt="" />
            <div class="item-mask"></div>
          </div>
          <!-- <img src="./images/zfgk.png" alt="" class="item-img"> -->
        </div>
        <div class="item sprh">
          <div class="item-img">
            <img src="./images/video-comb.png" alt="" />
            <div class="item-mask"></div>
          </div>
          <!-- <img src="./images/video-comb.png" alt="" class="item-img"> -->
          <div class="item-cont">
            <div class="cont-title">视频融合应用</div>
            <div class="cont-cont">
              视屏融合;平面到立体;虚拟与现实融合;三维定位、全景展示、跨境追踪;视屏巡逻;指哪看哪;目标跟踪;组合联动;入侵监测;视频选址分析;计算单机监控面积;计算监控盲区;分析与评估现状与规划点位覆盖情况
            </div>
          </div>
        </div>

        <div class="item nopeople">
          <div class="item-cont">
            <div class="cont-title">无人机智慧应用</div>
            <div class="cont-cont">
              无人机 +
              智能终端,丰富警务应用的感知范围、观测视角、采集方式,提升快速反应能力;无人机自动巡逻
            </div>
          </div>
          <div class="item-img">
            <img src="./images/nopeople.png" alt="" />
            <div class="item-mask"></div>
          </div>
          <!-- <img src="./images/nopeople.png" alt="" class="item-img"> -->
        </div>

        <div class="item VR">
          <div class="item-img">
            <img src="./images/ARksh-1.png" alt="" />
            <div class="item-mask"></div>
          </div>
          <!-- <img src="./images/ARksh-1.png" alt="" class="item-img"> -->
          <div class="item-cont">
            <div class="cont-title">VR 可视化应用</div>
            <div class="cont-cont">
              历史警情处置流程或事故现场进行数字化重置,实现三维复盘或追溯;预案动态交互;通过动态体感交互,对警务人员进行虚拟培训
            </div>
          </div>
        </div>

        <div class="item AR last">
          <div class="item-cont">
            <div class="cont-title">AR 可视化应用</div>
            <div class="cont-cont">
              在公安业务中,AR
              可视化能够实现场景测量、导航与透视分析,支持高效的远程协同交互
            </div>
          </div>
          <div class="item-img">
            <img src="./images/ARksh.png" alt="" />
            <div class="item-mask"></div>
          </div>
          <!-- <img src="./images/ARksh.png" alt="" class="item-img"> -->
        </div>
      </div>
    </div>
    <div class="gank"></div>
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
import './../solution.css';
import Footer from '@/components/Footer.vue';
export default {
  name: 'Zhjw',
  components: {
    Footer
  }
};
</script>

<style lang="less" scoped>
/********************* banner start **************/

.banner {
  background-image: url('./images/banner.png');
  background-size: contain;
  /* background-color: red; */
  position: relative;
}
.banner > .banner-text {
  position: absolute;
  width: 254px;
  top: 54%;
  left: 50%;
  transform: translate(-127px, -58%);
  z-index: 100;
  text-align: center;
}
.banner > .banner-text > p:first-child {
  font-size: 50px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 55px;
  letter-spacing: 10px;
}
.banner > .banner-text > p.disription {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
}
.banner > .banner-text > p.disription > .line {
  margin: 0 10px 0 9px;
}

.banner > .wrap > header > .secMune > .item.jjfa {
  left: 592px;
}

/********************* banner end **************/

/********************* 建设思路 start **************/

.jssl {
  background: #f5f5f5;
  border: 1px solid transparent;
  margin-top: 90px;
}
.jssl > .jssl-main {
  display: flex;
  margin-bottom: 90px;
  margin-top: 90px;
  justify-content: space-between;
}
.jssl > .jssl-main > .left {
  width: 523px;
}
.jssl > .jssl-main > .right {
  width: 470px;
  height: 353px;
}
.jssl > .jssl-main > .right > img {
  width: 100%;
  height: 100%;
}
.jssl > .jssl-main > .left > .title {
  margin-top: 0px;
}
.jssl > .jssl-main > .left > .title > p {
  text-align: left;
}
.jssl > .jssl-main > .left > .title > .hr {
  margin: 25px 0 60px 0;
}
.jssl > .jssl-main > .left > .title > .content {
  width: 523px;
  height: 128px;
  font-size: 16px;
  line-height: 34px;
  font-family: Source Han Sans CN;
  /* font-weight: bold;
    color: #999999; */
}
/********************* 建设思路 end **************/

/********************* 总体框架 start **************/

.ztkj > .content > img {
  display: block;
  height: 510px;
  width: 100%;
}
.ztkj > .content > .item.first {
  margin-top: 198px;
}
.ztkj > .content > .item.last {
  margin-bottom: 0;
}
.ztkj > .content > .item.ab {
  text-align: center;
}

.ztkj > .content > .item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}
.ztkj > .content > .item > .item-img {
  width: 458px;
  height: 280px;
  display: block;
  position: relative;
}
.ztkj > .content > .item > .item-img > img {
  width: 100%;
  height: 100%;
}
.ztkj > .content > .item > .item-img > .item-mask {
  width: 458px;
  height: 280px;
  position: absolute;
  top: 0;
  background: #366aed;
  opacity: 0.03;
  border-radius: 8px;
}
.ztkj > .content > .item > .item-cont {
  width: 458px;
  height: 220px;
  padding-top: 60px;
}
.ztkj > .content > .item > .item-cont > .cont-title {
  font-size: 24px;
  font-weight: 600;
  font-family: Source Han Sans CN;
  margin-bottom: 30px;
  text-align: center;
}
.ztkj > .content > .item > .item-cont > .cont-cont {
  font-size: 16px;
  font-family: Source Han Sans CN;
  /* font-weight: bold;
    color: #999999; */
}
/********************* 总体框架 end **************/
</style>
