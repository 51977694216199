<template>
  <div class="expansionPackage">
    <ServiceUpgradeTitle
      title="项目扩容包"
      bgTitle="PROJECT EXPANSION PACKAGE"
      subTitle="适合给单人项目扩容"
    />
    <div class="expansionPackage-container" v-if="packages.length">
      <ExpansionPackageCard
        class="expansionPackage-card"
        v-for="(item, index) in packages"
        :key="index"
        :data="item"
        @buy="$emit('buy', item)"
      />
    </div>
  </div>
</template>

<script>
import ExpansionPackageCard from './ExpansionPackageCard.vue';
import ServiceUpgradeTitle from './ServiceUpgradeTitle.vue';
export default {
  name: 'ExpansionPackage',
  components: {
    ExpansionPackageCard,
    ServiceUpgradeTitle
  },
  data() {
    return {
      packages: []
    };
  },
  created() {
    // 获取套餐信息
    this.$service.quota.packageList({ type: [2, 4] }).then(res => {
      const { status, data } = res;
      if (status === 200) {
        this.packages = data.map(item => {
          const { name } = item;
          if (name.indexOf('体验套餐') !== -1) {
            item.type = 'experience';
            item.subTitle = 'Experience package';
          }
          if (name.indexOf('进阶套餐') !== -1) {
            item.type = 'advanced';
            item.subTitle = 'Advanced package';
          }
          return item;
        });
      }
    });
  }
};
</script>

<style lang="less" scoped>
.expansionPackage {
  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 60px;
  }
}
</style>
