<template>
  <div class="sceneList">
    <el-tabs
      class="sceneList-tabs"
      v-model="componentType"
      @tab-click="handleClick"
    >
      <el-tab-pane
        v-for="(item, index) in list"
        :key="index"
        :index="item.type"
        :name="item.type"
      >
        <span slot="label" @mouseover="mouseOver(item)"
          ><img
            :src="require(`@/assets/${list[index].imgUrl}`)"
            :alt="item.title"
            style="width: 5.3vw" />
          <div class="sceneList-tabs-text">{{ item.title }}</div>
          <br
        /></span>
        <!-- <component :is="componentType" /> -->
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
// import Zhsw from './zhsw/Zhsw.vue';
// import Zhjw from './zhjw/Zhjw.vue';
// import Zhcg from './zhcg/Zhcg.vue';
// import Zhjt from './zhjt/Zhjt.vue';
// import Zhyj from './zhyj/Zhyj.vue';
// import Zhyq from './zhyq/Zhyq.vue';
// import Zhwl from './zhwl/Zhwl.vue';
// import Zhgt from './zhgt/Zhgt.vue';
export default {
  name: 'SceneList',
  // components: {
  //   Zhsw,
  //   Zhjw,
  //   Zhcg,
  //   Zhjt,
  //   Zhyj,
  //   Zhyq,
  //   Zhwl,
  //   Zhgt
  // },
  data() {
    return {
      componentType: 'Zhgt',
      list: [
        { title: '自然资源', type: 'Zhgt', imgUrl: 'icon-zrzy.png' },
        { title: '智慧水务', type: 'zhsw', imgUrl: 'icon-zhsw.png' },
        { title: '智慧警务', type: 'zhjw', imgUrl: 'icon-zhjw.png' },
        { title: '智慧城管', type: 'zhcg', imgUrl: 'icon-zhcg.png' },
        { title: '智慧交通', type: 'zhjt', imgUrl: 'icon-zhjt.png' },
        { title: '智慧应急', type: 'zhyj', imgUrl: 'icon-zhyj.png' },
        { title: '智慧园区', type: 'zhyq', imgUrl: 'icon-zhyq.png' },
        { title: '智慧文旅', type: 'zhwl', imgUrl: 'icon-zhwl.png' }
      ]
    };
  },
  methods: {
    handleClick(tab) {
      this.componentType = tab.$attrs.index;
    },
    mouseOver(item) {
      this.componentType = item.type;
    }
  }
};
</script>
<style lang="less" scoped>
.sceneList {
  margin: 0 auto;
  width: 1400px;
  background-size: contain;
  overflow: hidden;
  &-tabs {
    margin-top: 60px;
    /deep/.el-tabs__item {
      padding-left: 38px !important;
      padding-right: 35px !important;
    }
    /deep/.el-tabs__active-bar {
      width: 175px !important;
      margin-left: -35px;
    }
    /deep/ .btn {
      background: linear-gradient(270deg, #63eaff, #0096ff);
    }
    &-text {
      text-align: center;
      margin-top: -25px;
      margin-bottom: -9px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 40px;
    }
  }
}
</style>
