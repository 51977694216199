<template>
  <div class="packageSelection-card">
    <div
      class="packageSelection-card-top"
      :style="{ background: bgColor, color }"
    >
      <!-- 标题 -->
      <div class="packageSelection-card-title">
        <p>{{ data.name }}</p>
        <p class="packageSelection-card-title-sub">{{ data.subTitle }}</p>
      </div>
      <!-- 套餐详情 -->
      <div class="packageSelection-card-detail">
        <PackageSelectionCardDetailItem
          class="packageSelection-card-detail-item"
          :icon="blockImage"
          name="云空间"
        >
          {{ data.storageSpaceFormat }}
        </PackageSelectionCardDetailItem>
        <PackageSelectionCardDetailItem
          class="packageSelection-card-detail-item"
          :icon="blockImage"
          name="服务发布量"
        >
          {{ data.gissCount }}
        </PackageSelectionCardDetailItem>
      </div>
    </div>
    <!-- 价格 -->
    <div class="packageSelection-card-bottom">
      <div class="packageSelection-card-price">
        <!-- 有折扣价时 -->
        <template v-if="data.startPrice">
          <!-- 专享价 -->
          <div class="packageSelection-card-price-zxj">
            <p class="packageSelection-card-price-zxj-name">专享价</p>
            <p class="packageSelection-card-price-zxj-text">
              立省{{ ((data.price - data.startPrice) / 100).toFixed(1) }}元
            </p>
          </div>
          <!-- 折扣价 -->
          <div class="packageSelection-card-price-detail">
            <div class="packageSelection-card-price-detail-discount">
              <p>{{ (data.startPrice / 100).toFixed(1) }}</p>
            </div>
            <p class="packageSelection-card-price-detail-unit">元/月 起</p>
            <p class="packageSelection-card-price-detail-count">
              ￥{{ data.priceFormat }}
            </p>
          </div>
        </template>
        <!-- 没有折扣价时，显示原价 -->
        <template v-else>
          <div class="packageSelection-card-price-detail">
            <div class="packageSelection-card-price-detail-origin">
              <p>{{ (data.price / 100).toFixed(1) }}</p>
            </div>
          </div>
        </template>
      </div>
      <!-- 按钮 -->
      <Button
        class="packageSelection-card-price-btn"
        :style="{ 'background-color': color }"
        @click="$emit('buy')"
        >立即购买</Button
      >
    </div>
    <!-- 箭头 -->
    <img class="packageSelection-card-arrowImage" :src="arrowImage" alt="" />
  </div>
</template>

<script>
import Button from 'c/Button.vue';
import PackageSelectionCardDetailItem from './PackageSelectionCardDetailItem.vue';
export default {
  name: 'PackageSelectionCard',
  components: {
    Button,
    PackageSelectionCardDetailItem
  },
  props: {
    // 套餐类型
    type: {
      type: String,
      default: 'experience',
      validator: function (value) {
        // 体验、进阶
        return ['experience', 'advanced'].includes(value);
      }
    },
    data: Object
  },
  computed: {
    // 字体颜色
    color() {
      return {
        experience: '#0F74FD',
        advanced: '#B16307'
      }[this.type];
    },
    // 背景色
    bgColor() {
      return {
        experience: 'linear-gradient(131deg, #F3F5F7, #CCE5FF)',
        advanced: 'linear-gradient(131deg, #FFF1D3, #FFD894)'
      }[this.type];
    },
    blockImage() {
      return require(`./assets/block-${this.type}.png`);
    },
    arrowImage() {
      return require(`./assets/arrow-${this.type}.png`);
    }
  }
};
</script>

<style lang="less" scoped>
.packageSelection-card {
  position: relative;
  width: 636px;
  box-shadow: 0px 3px 5px 0px rgba(203, 203, 203, 0.3);
  border-radius: 16px;
  transition: all 200ms;
  &:hover {
    transform: scale(1.05);
  }
  &-arrowImage {
    position: absolute;
    top: -23px;
    right: 46px;
    width: 124px;
    height: 120px;
  }

  &-top {
    padding-bottom: 34px;
    border-radius: 16px 16px 0 0;
  }
  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 0 0 16px 16px;
  }
  &-title {
    display: flex;
    align-items: baseline;
    padding: 24px 0 0 32px;
    margin-bottom: 50px;
    font-size: 28px;
    font-weight: bold;
    &-sub {
      margin-left: 12px;
      opacity: 0.5;
      font-size: 12px;
      font-weight: normal;
    }
  }

  &-detail {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    &-item {
      flex: 1;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &-price {
    &-zxj {
      display: flex;
      align-items: baseline;
      margin-bottom: 20px;
      &-name {
        margin-right: 14px;
        color: #bd8915;
        font-size: 20px;
      }
      &-text {
        color: #ff0000;
        font-size: 16px;
      }
    }
    &-detail {
      display: flex;
      align-items: baseline;
      &-discount {
        display: flex;
        align-items: baseline;
        color: #ff0000;
        font-size: 36px;
        font-weight: bold;
        &::before {
          content: '￥';
          color: #ff0000;
          font-size: 16px;
        }
      }
      &-unit {
        color: #333333;
        font-size: 18px;
        margin-right: 25px;
      }
      &-count {
        text-decoration: line-through;
        color: #999;
        font-size: 20px;
      }
      &-origin {
        color: #333;
        font-size: 36px;
      }
    }
    &-btn {
      width: 158px;
      height: 52px;
      border-radius: 4px;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
</style>
