<template>
  <div>
    <!-- banner -->
    <div class="banner n1">
      <div class="banner-text w1200">
        <p>智慧应急</p>
        <p class="disription">
          地质灾害 <span class="line">|</span> 防汛救援
          <span class="line">|</span>油库消防
          <span class="line">|</span> 犯罪追捕<span class="line">|</span>
          智能大数据解决方案
        </p>
        <!-- <a href="javascript:">联系我们</a> -->
      </div>
      <div class="banner-mask"></div>
    </div>
    <!-- 解决方案 -->
    <div class="jjfa bigbt w1200">
      <div class="title">
        <p>解决方案</p>
        <div class="hr"></div>
      </div>
      <div class="jjfa-img">
        <img src="./images/opration.png" alt="" />
      </div>
    </div>

    <!-- 方案架构 -->

    <div class="fajg bigbt w1200">
      <div class="title">
        <p>方案架构</p>
        <div class="hr"></div>
      </div>
      <div class="fajg-img">
        <img src="./images/fangan-jiagou.png" alt="" />
      </div>
    </div>

    <!-- 应用方向 -->
    <div class="yyfx bigbt">
      <div class="title">
        <p>应用方向</p>
        <div class="hr"></div>
      </div>
      <div class="yyfx-content w1200">
        <div class="groups">
          <div class="item">
            <div class="item-img">
              <img src="./images/dizhi-harm.png" alt="" />
              <div class="item-mask"></div>
            </div>
            <div class="item-title">自然灾害-地质灾害应用</div>
            <div class="item-content">
              <p>
                应急物资布局、救援队伍行动规划、隐患点分级标识、地灾类型空间分析……
              </p>
            </div>
          </div>

          <div class="item">
            <div class="item-img">
              <img src="./images/fangxun-jiuyuan.png" alt="" />
              <div class="item-mask"></div>
            </div>
            <div class="item-title">自然灾害-防汛救援应用</div>
            <div class="item-content">
              <p>水深分析、管线分析、地形分析、影响人口结构分析……</p>
            </div>
          </div>

          <div class="item">
            <div class="item-img">
              <img src="./images/youku.png" alt="" />
              <div class="item-mask"></div>
            </div>
            <div class="item-title">事故灾害-油库消防应用</div>
            <div class="item-content">
              <p>
                基于内部空间结构，可对风险点进行精细化管理，模拟推演火灾影响范围和趋势，快速有效布局人、机、物，避免火情分析不充分导致二次灾害发生。
              </p>
            </div>
          </div>
        </div>

        <div class="groups">
          <div class="item">
            <div class="item-img">
              <img src="./images/crimal.png" alt="" />
              <div class="item-mask"></div>
            </div>
            <div class="item-title">社会安全-犯罪追捕应用</div>
            <div class="item-content">
              <p>
                划定追捕范围，分析嫌疑人出逃路径，发出规避风险区域提示，立体布控资源，分析制高点……
              </p>
            </div>
          </div>

          <div class="item">
            <div class="item-img">
              <img src="./images/fangyi.png" alt="" />
              <div class="item-mask"></div>
            </div>
            <div class="item-title">公共卫生-防疫防控应用</div>
            <div class="item-content">
              <p>大数据轨道追踪 网格化管理</p>
            </div>
          </div>

          <div class="item">
            <div class="item-img">
              <img src="./images/nopeople.png" alt="" />
              <div class="item-mask"></div>
            </div>
            <div class="item-title">无人机快速反映服务</div>
            <div class="item-content">
              <p>
                夜间探照、高清变焦、热成像、应急救援、高层消防、区域活动监管、森林消防
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="gank"></div>
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
import './../solution.css';
import Footer from '@/components/Footer.vue';
export default {
  name: 'Zhsw',
  components: {
    Footer
  }
};
</script>

<style lang="less" scoped>
/********************* banner start **************/

.banner {
  background-image: url('./images/banner.png');
  background-size: contain;
  /* background-color: red; */
  position: relative;
}
.banner > .banner-text {
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -38%);
  z-index: 100;
}
.banner > .banner-text > p:first-child {
  font-size: 50px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 35px;
  letter-spacing: 10px;
}
.banner > .banner-text > p.disription {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
}
.banner > .banner-text > p.disription > .line {
  margin: 0 10px 0 9px;
}
.banner > .banner-text > a {
  width: 247px;
  height: 65px;
  border: 2px solid #ffffff;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 65px;
  text-align: center;
  display: block;
  margin-top: 36px;
  border-radius: 6px;
}

.banner > .wrap > header > .secMune > .item.jjfa {
  left: 592px;
}

/********************* banner end **************/

/********************* 解决方案 start **************/
/* .jjfa.bigbt{
    padding:0;
} */

.jjfa > .jjfa-img {
  width: 100%;
  height: 600px;
  margin: 95px 0 0 0;
  display: flex;
  align-items: center;
}
.jjfa > .jjfa-img > img {
  height: 480px;
  /* width: 100%; */
}
/********************* 解决方案 end **************/

/********************* 方案架构 start **************/

.fajg > .fajg-img {
  width: 1200px;
  height: 640px;
  margin: 95px 0 0 0;
}
.fajg > .fajg-img > img {
  height: 100%;
  width: 100%;
}

/********************* 方案架构 end **************/

/********************* 应用方向 start **************/

.yyfx > .yyfx-content {
  margin-top: 120px;
}
.yyfx > .yyfx-content > .groups {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.yyfx > .yyfx-content > .groups > .item {
  width: 385px;
  box-shadow: 0px 3px 8px 0px rgba(182, 182, 182, 0.2),
    0px -3px 7px 0px rgba(182, 182, 182, 0.2);
  height: 500px;
}
.yyfx > .yyfx-content > .groups > .item > .item-img {
  width: 100%;
  height: 250px;
  position: relative;
  overflow: hidden;
}
.yyfx > .yyfx-content > .groups > .item > .item-img:hover > img {
  transform: scale(1.05);
}
.yyfx > .yyfx-content > .groups > .item > .item-img > img {
  width: 100%;
  height: 100%;
  transition: all 0.5s ease;
}
.yyfx > .yyfx-content > .groups > .item > .item-img > .item-mask {
  width: 385px;

  height: 250px;
  background: #3065ee;
  opacity: 0.1;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.yyfx > .yyfx-content > .groups > .item > .item-title {
  width: 100%;
  text-align: center;
  height: 28px;
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  margin: 40px 0 30px 0;
}
.yyfx > .yyfx-content > .groups > .item > .item-content {
  width: 100%;
  /* text-align: center; */
  /* text-indent: 36px; */
  height: 66px;
  font-size: 18px;
  line-height: 30px;
  font-family: Source Han Sans CN;
  /* font-weight: 400; */
  color: #333;
}
.yyfx > .yyfx-content > .groups > .item > .item-content > p {
  padding: 0 20px;
  /* text-align: center; */
}

.yyfx
  > .yyfx-content
  > .groups:nth-child(2)
  > .item:nth-child(2)
  > .item-content
  > p {
  text-align: center;
}
/********************* 应用方向 end **************/
</style>
