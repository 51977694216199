<template>
  <el-dialog
    :visible="true"
    width="774px"
    title="购买套餐"
    :close-on-click-modal="false"
    class="package-play-dialog"
    :before-close="
      () => {
        $emit('close');
      }
    "
  >
    <!-- 套餐内容 -->
    <div class="package-content">
      <!-- 套餐 -->
      <template v-if="data.type === 1">
        <p class="package-content-title">套餐内容：</p>
        <div class="package-content-wrap">
          <div class="package-content-item">
            云空间：{{ data.storageSpaceFormat }}
          </div>
          <div class="package-content-item">
            服务发布量：{{ data.gissCount }}
          </div>
        </div>
      </template>
      <!-- 云空间 -->
      <template v-if="data.type === 2">
        <p class="package-content-title">购买数量：</p>
        <div class="package-content-wrap">
          <div class="package-content-label">云空间数量(GB)：</div>
          <el-input-number
            class="package-content-inputNum"
            :min="1"
            v-model="storageSpaceNum"
          />
        </div>
      </template>
      <!-- 服务发布量 -->
      <template v-if="data.type === 4">
        <p class="package-content-title">购买数量：</p>
        <div class="package-content-wrap">
          <div class="package-content-label">服务发布量：</div>
          <el-input-number
            class="package-content-inputNum"
            :min="1"
            v-model="gissCountNum"
          />
        </div>
      </template>
    </div>
    <!-- 购买时长 -->
    <div class="package-time">
      <p class="package-time-title">购买时长</p>
      <div class="package-time-list">
        <div
          class="package-time-item"
          :class="currentData.goodsId === item.goodsId ? 'active' : ''"
          v-for="(item, index) in times"
          :key="index"
          @click="handleTimeClick(item)"
        >
          <p class="package-time-item-name">{{ item.buyLength }}</p>
          <p class="package-time-item-discount" v-if="item.discount !== 100">
            {{ item.discount / 10 }}折
          </p>
        </div>
      </div>
      <p class="package-time-tip">
        套餐内容到期时间为：{{ endTime }}；从套餐购买日开始计算
      </p>
    </div>
    <!-- 金额 -->
    <div class="package-price">
      <div class="package-price-top">
        <p>实付金额：</p>
        <p class="package-price-unit">￥</p>
        <p class="package-price-count">{{ money }}</p>
      </div>
      <div class="package-price-playType">
        <div
          class="package-price-playType-item"
          v-for="item in playTypeArr"
          :key="item.name"
          @click="handlePlay(item)"
        >
          <p class="package-price-playType-item-name">{{ item.name }}</p>
          <img class="package-price-playType-item-img" :src="item.url" alt="" />
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'PackagePlayDialog',
  props: {
    // 套餐内容
    data: Object
  },
  data() {
    return {
      currentData: '',
      times: [],
      storageSpaceNum: 1,
      gissCountNum: 1,
      playTypeArr: [
        { name: '微信', url: require('a/wx.png'), type: 2 },
        { name: '支付宝', url: require('a/zfb.png'), type: 1 }
      ]
    };
  },
  computed: {
    // 实付价格
    money() {
      const { discountPrice, price } = this.currentData;
      const { type } = this.data;
      const { storageSpaceNum, gissCountNum } = this;
      let money = 0;
      let basePrice = discountPrice ? discountPrice : price;
      switch (type) {
        case 1:
          money = (basePrice / 100).toFixed(2);
          break;
        case 2:
          money = ((basePrice * storageSpaceNum) / 100).toFixed(2);
          break;
        case 4:
          money = ((basePrice * gissCountNum) / 100).toFixed(2);
          break;
      }
      return money;
    },
    orderName() {
      let orderName = '';
      const { type, name } = this.data;
      const { buyLength } = this.currentData;
      const { storageSpaceNum, gissCountNum } = this;
      switch (type) {
        case 1:
          orderName = name + ' ' + buyLength;
          break;
        case 2:
          orderName = `${name}${storageSpaceNum}G ${buyLength}`;
          break;
        case 4:
          orderName = `${name}${gissCountNum}个 ${buyLength}`;
          break;
      }
      return orderName;
    },
    // 计算套餐到期时间
    endTime() {
      let date = new Date();
      let y = date.getFullYear();
      let M = date.getMonth() + 1;
      let d = date.getDate();
      let h = date.getHours();
      let m = date.getMinutes();
      let s = date.getSeconds();
      // 加上套餐时间
      let addMonth = this.currentData.monthNumber;
      // 计算增加的年份
      y += Math.floor(addMonth / 12);
      // 计算多余的月份
      if (addMonth % 12 !== 0) {
        M += addMonth % 12;
        if (M > 12) {
          y += 1;
          M = M - 12;
        }
      }
      M = M < 10 ? '0' + M : M;
      d = d < 10 ? '0' + d : d;
      h = h < 10 ? '0' + h : h;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      return `${y}年${M}月${d}日 ${h}:${m}:${s}`;
    }
  },
  created() {
    // 获取套餐包详情
    this.$service.quota.packagePrice({ packageId: this.data.id }).then(res => {
      const { status, data } = res;
      if (status === 200) {
        this.times = data;
        this.currentData = this.times[0];
      }
    });
  },
  methods: {
    // 点击支付类型
    handlePlay(item) {
      const quotaPriceId = this.currentData.id;
      const { type: packageType } = this.data;
      const payChannel = item.type;
      const payMethod = 3;
      let count = 1;
      switch (packageType) {
        case 2:
          count = this.storageSpaceNum;
          break;
        case 4:
          count = this.gissCountNum;
          break;
      }
      const params = {
        quotaPriceId,
        count,
        payChannel,
        payMethod
      };
      // 创建订单
      this.$service.quota.orderCreate(params).then(res => {
        const { status, data } = res;

        if (status === 200) {
          const orderId = data.orderId;
          // 订单支付
          this.$service.payment.payOrder({ orderId }).then(res => {
            const { status, data } = res;
            if (status === 200) {
              const query = {
                codeUrl: data.codeUrl,
                orderId,
                orderName: this.orderName,
                money: this.money
              };
              // 支付宝
              if (payChannel === 1) {
                const win = document.open('', '', 'width=1200,height=845');
                win.document.write(data.responseBody);
              }
              // 微信
              if (payChannel === 2) {
                // 跳转到微信支付页面
                const route = this.$router.resolve({
                  path: '/wxPay',
                  query
                });
                window.open(route.href, orderId);
              }
              this.$emit('close');
              // 支付结果
              this.$emit('openPayResultDialog', query);
            }
          });
        }
      });
    },
    // 切换时长
    handleTimeClick(data) {
      this.currentData = data;
    }
  }
};
</script>

<style lang="less" scoped>
.package-play-dialog {
  /deep/ .el-dialog {
    &__body {
      padding: 17px 50px 60px 50px;
    }
  }
}

.package-content {
  margin-bottom: 40px;
  &-title {
    color: #333333;
    font-size: 20px;
    margin-bottom: 30px;
  }
  &-wrap {
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
  &-item {
    width: 180px;
    height: 40px;
    margin-right: 40px;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    &:last-child {
      margin-right: 0;
    }
    &:nth-child(1) {
      color: #0f74fd;
      background: linear-gradient(131deg, #f0f8ff, #cce5ff);
    }
    &:nth-child(2) {
      color: #a55d07;
      background: linear-gradient(131deg, #fff1d3, #ffd894);
    }
  }
  &-label {
    color: #333;
    font-size: 16px;
  }
  &-inputNum {
    flex: 1;
  }
}

.package-time {
  margin-bottom: 30px;
  &-title {
    color: #333;
    font-size: 20px;
    margin-bottom: 27px;
  }
  &-list {
    padding: 0 20px;
  }
  &-item {
    display: inline-block;
    position: relative;
    width: 110px;
    height: 40px;
    margin-right: 16px;
    margin-bottom: 20px;
    background: #ffffff;
    border: 1px solid #999999;
    border-radius: 4px;
    color: #333;
    cursor: pointer;
    &:nth-child(5n) {
      margin-right: 0;
    }
    &.active {
      background: #0096ff;
      color: #fff;
    }
    &-name {
      font-size: 20px;
      text-align: center;
      line-height: 40px;
    }
    &-discount {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(10px, -50%);
      width: 39px;
      height: 18px;
      background: #ff0000;
      border-radius: 4px;
      color: #fff;
      font-size: 12px;
      text-align: center;
      line-height: 18px;
    }
  }
  &-tip {
    padding-left: 20px;
    color: #666;
    font-size: 14px;
  }
}

.package-price {
  margin: 0 20px;
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 4px;
  &-top {
    display: flex;
    justify-content: center;
    align-items: baseline;
    height: 70px;
    border-bottom: 1px solid #c9c9c9;
    color: #999999;
    font-size: 16px;
  }
  &-unit {
    color: #ff6600;
    font-size: 20px;
  }
  &-count {
    color: #ff6600;
    font-size: 50px;
  }
  &-playType {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 170px;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 130px;
      height: 130px;
      margin: 0 25px;
      background: #ffffff;
      border: 1px solid #999999;
      border-radius: 10px;
      cursor: pointer;
      &-name {
        margin-bottom: 26px;
        color: #333;
        font-size: 18px;
      }
      &-img {
        width: 42px;
        height: 42px;
      }
    }
  }
}
</style>
