<template>
  <div>
    <!-- banner -->
    <div class="banner">
      <div class="banner-text">
        <p>智慧城管一体化解决方案</p>
        <p class="disription">
          基于神州龙自主研发的数字孪生平台（longmap），以实景三维地图为空间载体，实现
        </p>
        <p class="disription">
          城管部件、事件和管理要素的实体化和可视化，全面提升城市治
        </p>
        <p class="disription">理精细化、智能化水平。</p>
        <!-- <a href="javascript:">联系我们</a> -->
      </div>
    </div>
    <!-- 基础特征 -->
    <div class="jctz bigbt">
      <div class="title">
        <p>基础特征</p>
        <div class="hr"></div>
      </div>
      <div class="content w1200">
        <div class="item">
          <div class="item-img">
            <img src="./images/ganzhi.gif" alt="" />
          </div>
          <p class="tit">全面透彻的感知</p>
          <p class="disription">
            通过传感技术，实现对城市管理各方面监测和全面感知,智能识别、立体感知城市环境、状态、位置等信息的全方位变化
          </p>
        </div>
        <div class="item">
          <div class="item-img">
            <img src="./images/hulian.gif" alt="" />
          </div>
          <p class="tit">宽带泛在的互联</p>
          <p class="disription">
            各类宽带有线、无线网络技术的发展为城市中物与物、人与物、人与人的全面互联、互通、互动，为城市各类随时、随地、随需、随意应用提供了基础条件。
          </p>
        </div>
        <div class="item">
          <div class="item-img">
            <img src="./images/yy.gif" alt="" />
          </div>
          <p class="tit">智能融合的应用</p>
          <p class="disription">
            海量数据的集成与融合，驱动大数据应用，辅助预警预测与综合决策。
          </p>
        </div>
        <div class="item">
          <div class="item-img">
            <img src="./images/cx.gif" alt="" />
          </div>
          <p class="tit">以人为本的可持续创新</p>
          <p class="disription">
            注重以人为本、市民参与、社会协同的开放创新空间的塑造以及公共价值与独特价值的创造。
          </p>
        </div>
      </div>
    </div>

    <!-- 整体架构 -->
    <div class="ztjg">
      <img src="./images/ztjg.png" alt="" />
    </div>

    <!-- 解决方案 -->
    <div class="jjfa w1200 bigbt">
      <div class="title">
        <p>解决方案</p>
        <div class="hr"></div>
      </div>
      <div class="groups">
        <div class="item">
          <div class="item-main">
            <div class="item-up">
              <img src="./images/sw.png" alt="" />
            </div>
            <div class="item-down">
              <p>三维可视化</p>
            </div>
          </div>
          <div class="detail">
            <p class="tit">三维可视化</p>
            <p class="disription">
              使用具备真实纹理与真实尺寸的真景三维模型与多精度的仿真三维模型构建城市场景。精准还原城市面貌，为城管业务应用提供精准定位、多维度实景参考与立体空间分析支撑。
            </p>
          </div>
        </div>
        <div class="item">
          <div class="item-main">
            <div class="item-up">
              <img src="./images/sjksh.png" alt="" />
            </div>
            <div class="item-down">
              <p>数据可视化</p>
            </div>
          </div>
          <div class="detail">
            <p class="tit">数据可视化</p>
            <p class="disription">
              数字可视化指的是将数据用统计图表方式呈现，而信息图形（信息可视化）则是将非数字的信息进行可视化。前者用于传递信息，后者用于表现抽象或复杂的概念、技术和信息。
            </p>
          </div>
        </div>
        <div class="item">
          <div class="item-main">
            <div class="item-up">
              <img src="./images/jcyyj.png" alt="" />
            </div>
            <div class="item-down">
              <p>监测与预警</p>
            </div>
          </div>
          <div class="detail">
            <p class="tit">监测与预警</p>
            <p class="disription">
              基于5G+定位技术，设置智能电子围栏，对公务车辆、环卫车辆、渣土车、智能终端、人员等实时定位与预警。接入各类传感器，实现城市运行监测与预警。
            </p>
          </div>
        </div>
      </div>
      <div class="groups">
        <div class="item">
          <div class="item-main">
            <div class="item-up">
              <img src="./images/jkrh.png" alt="" />
            </div>
            <div class="item-down">
              <p>视频融合</p>
            </div>
          </div>
          <div class="detail">
            <p class="tit">视频融合</p>
            <p class="disription">
              将智能终端、视频监控采集的视频画面实时映射于三维场景中，并对视频进行分析，辅助决策。
            </p>
          </div>
        </div>
        <div class="item">
          <div class="item-main">
            <div class="item-up">
              <img src="./images/ssgl.png" alt="" />
            </div>
            <div class="item-down">
              <p>设施管理</p>
            </div>
          </div>
          <div class="detail">
            <p class="tit">设施管理</p>
            <p class="disription">
              对环境卫生、市政设施、公共设施、执法装备智能化管理，实时监控设备设施数据，智能调度设备。
            </p>
          </div>
        </div>
        <div class="item">
          <div class="item-main">
            <div class="item-up">
              <img src="./images/mnfx.png" alt="" />
            </div>
            <div class="item-down">
              <p>模拟分析</p>
            </div>
          </div>
          <div class="detail">
            <p class="tit">模拟分析</p>
            <p class="disription">
              违法施工智能分析、填挖方分析、日照分析、水淹分析、视频分析。
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- 经典案例 -->
    <div class="jdal bigbt">
      <div class="title">
        <p>经典案例</p>
        <div class="hr"></div>
      </div>
      <div class="content w1200">
        <div class="item">
          <img src="./images/tmc.png" alt="" />
          <p>垃圾填埋场三维综合管理平台</p>
        </div>
        <div class="item">
          <img src="./images/zfpt.png" alt="" />
          <p>智慧城管执法平台</p>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
import './../solution.css';
import Footer from '@/components/Footer.vue';
export default {
  name: 'Zhsw',
  components: {
    Footer
  }
};
</script>

<style lang="less" scoped>
/********************* banner start **************/

.banner {
  background-image: url('./images/banner.png');
  /* background-color: red; */
  height: 1084px;
}

.banner > .banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-88%, -50%);
  z-index: 10;
}

.banner > .banner-text > p:first-child {
  font-size: 50px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 56px;
  letter-spacing: 4px;
}
.banner > .banner-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #131d3b;
  opacity: 0.6;
  top: 0;
  /* margin-top: -72px; */
}
.banner > .banner-text > p.disription {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
}
.banner > .wrap > header > .secMune > .item.jjfa {
  left: 594px;
}
/********************* banner end **************/

/********************* 基础特征 start **************/

.jctz {
  background: #fff;
  overflow: hidden;
  padding-bottom: 181px !important;
}

.jctz > .content {
  display: flex;
  /* justify-content: ; */
  align-items: center;
  justify-content: space-between;
  margin-top: 121px;
}

.jctz > .content > .item {
  width: 278px;
  height: 420px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* justify-content: ; */
  align-items: center;
  padding: 30px 20px 0 20px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 3px 7px 0px rgba(182, 182, 182, 0.2),
    0px -3px 7px 0px rgba(182, 182, 182, 0.2);
  border-radius: 8px;
}

.jctz > .content > .item > .item-img {
  width: 178px;
  height: 178px;
  margin-bottom: 11px;
}
.jctz > .content > .item > .item-img > img {
  width: 100%;
  height: 100%;
}
.jctz > .content > .item > .tit {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  white-space: nowrap;
}

.jctz > .content > .item > .disription {
  font-size: 16px;
  /* font-weight: 600; */
  color: #333333;
  margin-top: 24px;
  letter-spacing: 2px;
}

/********************* 基础特征 end **************/

/********************* 整体架构 start **************/

.ztjg {
  height: auto;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
}
.ztjg > img {
  display: block;
  width: 100%;
}

/********************* 整体架构 end **************/

/********************* 解决方案 start **************/

.jjfa > .groups {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}
.jjfa .groups:nth-child(2) {
  margin-top: 30px;
}
.jjfa > .groups > .item {
  width: 240px;
  height: 320px;
  background: #ffffff;
  box-shadow: 0px 3px 8px 0px rgba(182, 182, 182, 0.2),
    0px -3px 7px 0px rgba(182, 182, 182, 0.2);
  border-radius: 8px;
  transition: width 0.3s;
}
.jjfa > .groups > .item > .item-main {
  padding: 0px 20px;
  height: 80%;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.jjfa > .groups > .item > .item-main > .item-up {
  width: 110px;
  height: 110px;
}
.jjfa > .groups > .item > .item-main > .item-up > img {
  width: 100%;
  height: 100%;
}
.jjfa > .groups > .item > .item-main > .item-down {
  /* width: 118px; */
  height: 36px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
}
.jjfa > .groups > .item > .detail {
  display: none;
  height: 100%;
  width: 386px;
  height: 320px;
  padding: 0 49px;
}
.jjfa > .groups > .item:hover > .detail {
  display: block;
}
.jjfa > .groups > .item:hover > .item-main {
  display: none;
}
.jjfa > .groups > .item:hover {
  width: 482px;
  /* transform: s; */
}
.jjfa > .groups > .item > .detail > .tit {
  font-size: 42px;
  font-weight: 600;
  color: #333;
  margin-top: 54px;
  text-align: center;
}
.jjfa > .groups > .item > .detail > .disription {
  font-size: 16px;
  /* font-weight: 600; */
  color: #333;
  margin-top: 45px;
  line-height: 30px;
}
/********************* 解决方案 end **************/

/********************* 经典案例 start **************/

.jdal > .content > .item > img {
  display: block;
  width: 504px;
  height: 304px;
}
/********************* 经典案例 end **************/
</style>
