<template>
  <div class="home">
    <!-- 第一屏 -->
    <div class="home-one">
      <!-- 左侧 -->
      <div class="home-one-left">
        <!-- 介绍 -->
        <div class="home-one-left-intro">
          <!-- 标题 -->
          <h1 class="home-one-left-intro-title">神州龙数字孪生云平台longmap</h1>
          <!-- 内容 -->
          <p class="home-one-left-intro-content">
            基于GIS、BIM、CIM的一站式可视化应用服务
          </p>
          <p class="home-one-left-intro-btn">
            时空数据存储、处理、分析、发布；数字孪生可视化场景建构
          </p>
        </div>
        <!-- 按钮 -->
        <div class="home-one-left-button">
          <Button class="btn" @click.native="goWorkbench">
            进入工作台
            <i class="icon icon-enter"></i>
          </Button>
        </div>
      </div>
    </div>
    <!-- 第二屏 -->
    <div class="home-two">
      <!-- 标题 -->
      <h1 class="longmapPT-home-title">产品特色</h1>
      <h1 class="longmapPT-home-title-EN">PRODUCT FEATURES</h1>
      <!-- 内容 -->
      <div class="home-two-content">
        <div
          class="home-two-content-nav"
          v-for="(nav, index) in navs"
          :key="index"
        >
          <img
            :src="require(`../assets/${navs[index].imgUrl}`)"
            :alt="nav.name"
          />
          <div style="text-align: center">{{ nav.name }}</div>
        </div>
      </div>
    </div>
    <!-- 第三个屏 -->
    <div class="home-three">
      <!-- 标题 -->
      <h1 class="longmapPT-home-title">应用场景</h1>
      <h1 class="longmapPT-home-title-EN">APPLICATION SCENARIO</h1>
      <!-- 内容 -->
      <scene-list></scene-list>
    </div>
    <!-- 定制开发和外包服务 -->
    <div class="home-four">
      <!-- 标题 -->
      <h1 class="longmapPT-home-title">定制开发和外包服务</h1>
      <h1 class="longmapPT-home-title-EN">
        CUSTOMIZED DEVELOPMENT AND OUTSOURCING SERVICES
      </h1>
      <div class="item-wrap">
        <div class="item">支持数字孪生云平台搭建</div>
        <div class="item">支持三维可视化平台定制开发</div>
        <div class="item">支持GIS项目服务外包</div>
      </div>
      <div class="item-wrap">
        <div class="item">支持定制实景三维平台</div>
        <div class="item">支持实景三维系统开发</div>
        <div class="item">支持CIM平台搭建</div>
        <div class="item">支持宅基地监测管理平台</div>
      </div>
      <div class="bottom">
        <div class="circle-list">
          <div class="circle f"></div>
          <div class="circle s"></div>
          <div class="circle t"></div>
        </div>
        <p class="center">专业团队.积极响应.快速交付.优质服务</p>
        <div class="circle-list">
          <div class="circle t"></div>
          <div class="circle s"></div>
          <div class="circle f"></div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
import Button from 'c/Button';
import Footer from '@/components/Footer.vue';
import SceneList from './scene/SceneList.vue';
export default {
  name: 'Home',
  components: {
    Button,
    Footer,
    SceneList
  },
  data() {
    return {
      navs: [
        {
          name: '二三维一体化\n渲染引擎',
          imgUrl: 'cpts-1.png'
        },
        {
          name: '多源异构GIS\n数据加载',
          imgUrl: 'cpts-2.png'
        },
        {
          name: '标准地图服务\n发布',
          imgUrl: 'cpts-3.png'
        },
        {
          name: '地图制图表达',
          imgUrl: 'cpts-4.png'
        },
        {
          name: '矢量、栅格、模型\n数据管理',
          imgUrl: 'cpts-5.png'
        },
        {
          name: 'BIM数据\n轻量化应用',
          imgUrl: 'cpts-6.png'
        },
        {
          name: '跨平台应用，支持\n国产化环境',
          imgUrl: 'cpts-7.png'
        },
        {
          name: '丰富的二次开发\n接口',
          imgUrl: 'cpts-8.png'
        },
        {
          name: '高性能、低成本',
          imgUrl: 'cpts-9.png'
        },
        {
          name: '私有化部署',
          imgUrl: 'cpts-10.png'
        }
      ]
    };
  },
  methods: {
    // 工作台跳转判断
    goWorkbench() {
      // window.open(window.dataManagerPage,'dataManagerPage')
      this.$emit('openNoticeDialog');
    }
  }
};
</script>

<style lang="less" scoped>
.home {
  height: 100%;
  //第一个屏
  &-one {
    height: 808px;
    background-image: url(~a/home-one-bg.png);
    background-size: contain;
    overflow: hidden;
    // 左侧
    &-left {
      width: 600px;
      margin-top: 212px;
      margin-left: 260px;
      // Logo
      &-logo {
        height: 86px;
        width: 358px;
        background-size: contain;
        background-image: url('../assets/logo-b.png');
      }
      // 介绍
      &-intro {
        margin-top: 34px;
        margin-bottom: 70px;
        // 标题
        &-title {
          margin-bottom: 18px;
          color: #000000;
          font-size: 40px;
        }
        // 内容
        &-content {
          line-height: 32px;
          color: #333333;
          font-size: 20px;
        }
        &-btn {
          display: inline-block;
          height: 43px;
          padding: 0 10px;
          margin-top: 60px;
          line-height: 43px;
          border: 1px solid #0096ff;
          border-radius: 4px;
          text-align: center;
          color: #0096ff;
          font-size: 18px;
        }
      }
      // 按钮
      &-button {
        display: flex;
        align-items: center;
        margin-top: 110px;
        .btn {
          width: 197px;
          height: 57px;
          background: linear-gradient(270deg, #63eaff, #0096ff);
          border-radius: 8px;
          .icon {
            margin-left: 20px;
          }
        }
      }
    }
  }
  //第二个屏
  &-two {
    padding-bottom: 110px;
    &-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 150px;
      &-nav {
        width: 130px;
        margin: 0 80px;
        text-align: center;
        margin-top: 100px;
        font-size: 18px;
        color: #333333;
        img {
          width: 100px;
          margin-bottom: 20px;
        }
      }
    }
  }
  //第三个屏
  &-three {
    height: 300px;
    background: linear-gradient(227deg, #f4f8fb, #eaf9fe);
    background-size: contain;
    overflow: hidden;
  }
  &-four {
    box-sizing: border-box;
    height: 423px;
    padding-bottom: 50px;
    background-image: url(~a/home-scene-four-bg.png);
    background-size: contain;
    overflow: hidden;
    .item {
      width: 338px;
      height: 57px;
      margin: 0 20px;
      background: linear-gradient(270deg, #0297ff, #0096ff);
      border-radius: 8px;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      line-height: 57px;
      &-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 200px;
        margin-top: 40px;
      }
    }
    .bottom {
      display: flex;
      justify-content: center;
      margin-top: 60px;
      .circle {
        width: 8px;
        height: 8px;
        margin: 0 3px;
        background: #666666;
        border-radius: 50%;
        &-list {
          display: flex;
          align-items: center;
        }
        &.f {
          opacity: 0.2;
        }
        &.s {
          opacity: 0.4;
        }
        &.t {
          opacity: 0.8;
        }
      }
      .center {
        margin: 0 16px;
        color: #666;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}
</style>
