<template>
  <el-dialog
    class="pay-result-dialog"
    title="支付结果"
    width="664px"
    :visible="true"
    :close-on-click-modal="false"
    :before-close="
      () => {
        $emit('close');
      }
    "
  >
    <div class="pay-result">
      <div class="pay-result-info">
        <div class="pay-result-info-item">
          <p class="pay-result-info-item-name">支付结果:</p>
          <p class="pay-result-info-item-text">￥{{ data.money }}</p>
        </div>
        <div class="pay-result-info-item">
          <p class="pay-result-info-item-name">购买内容:</p>
          <p class="pay-result-info-item-text">{{ data.orderName }}</p>
        </div>
        <!-- <div class="pay-result-info-item">
          <p class="pay-result-info-item-name">完成时间:</p>
          <p class="pay-result-info-item-text">{{ data.time }}</p>
        </div> -->
        <div class="pay-result-info-item">
          <p class="pay-result-info-item-name">订单号:</p>
          <p class="pay-result-info-item-text">{{ data.orderId }}</p>
          <el-button
            class="copy-btn"
            type="text"
            v-clipboard:copy="data.orderId"
            v-clipboard:success="success"
            v-clipboard:error="error"
            >复制</el-button
          >
        </div>
      </div>
      <div class="pay-result-tip">
        <p class="pay-result-tip-top">
          请在新打开的页面上进行支付，支付完成后再关闭此窗口
        </p>
        <p class="pay-result-tip-bottom">
          如果支付遇到问题或没到账，请<span class="contact">联系客服</span>
        </p>
      </div>
      <div class="pay-result-bottom">
        <Button class="pay-result-bottom-btn" @click="$emit('close')"
          >已完成支付</Button
        >
        <Button
          class="pay-result-bottom-btn"
          @click="$router.push('/workbench/buyRecord')"
          >账单详情</Button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Button from 'c/basic/Button';
export default {
  name: 'PayResultDialog',
  components: {
    Button
  },
  props: {
    data: Object
  },
  methods: {
    // 复制
    success() {
      this.$message.success('复制成功');
    },
    error() {
      this.$message.success('复制失败');
    }
  }
};
</script>

<style lang="less" scoped>
.pay-result {
  &-info {
    padding: 0 30px;
    font-size: 20px;
    color: #333;
    &-item {
      display: flex;
      margin-bottom: 30px;
      &-name {
        width: 85px;
        margin-right: 30px;
      }
      .copy-btn {
        margin-left: 30px;
        color: #0096ff;
        font-size: 14px;
      }
    }
  }
  &-tip {
    margin-top: 50px;
    margin-bottom: 30px;
    text-align: center;
    &-top {
      margin-bottom: 25px;
      color: #333;
      font-size: 20px;
      font-weight: bold;
    }
    &-bottom {
      color: #999999;
      font-size: 16px;
    }
    .contact {
      color: #ff6600;
    }
  }
  &-bottom {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    border-top: 1px solid #c9c9c9;
    &-btn {
      width: 130px;
      height: 48px;
      margin: 0 25px;
      border-radius: 24px;
      background-color: #fff;
      font-size: 18px;
      &:nth-child(1) {
        border: 1px solid #999999;
        color: #333;
      }
      &:nth-child(2) {
        border: 1px solid #0096ff;
        color: #0096ff;
        font-weight: bold;
      }
    }
  }
}
</style>
