<template>
  <div class="home">
    <!-- 第一屏 -->
    <div class="home-one">
      <!-- 左侧 -->
      <div class="home-one-left">
        <!-- 介绍 -->
        <div class="home-one-left-intro">
          <h1 class="home-one-left-intro-title">私有化部署</h1>
          <h2 class="home-one-left-intro-content">
            安全高效，灵活开发，自主可控，兼容国产信创环境，满足企业和政府等业务场景个性化部署与定制需求
          </h2>
        </div>
        <div class="home-one-left-button">
          数据私密性 • 纯内网部署 • 专属管理后台 • 专业技术支持
        </div>
      </div>
      <!-- 右侧 -->
      <div class="home-one-right">
        <el-form ref="formRef" :model="form" :rules="formRules">
          <el-form-item style="margin-top: 1.5vw" prop="mobile">
            <el-input
              v-model="form.mobile"
              placeholder="手机号"
              maxlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item prop="name">
            <el-input
              v-model="form.name"
              placeholder="如何称呼您"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item prop="demand">
            <el-input
              type="textarea"
              :rows="4"
              maxlength="1000"
              v-model="form.demand"
              placeholder="简单描述您的需求"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div class="bottom">
              <el-button
                class="home-one-right-btn"
                @click="submitForm('formRef')"
              >
                提交需求
                <p>(1个工作日内回复)</p>
              </el-button>
              <div class="home-one-right-text">
                <span>立即咨询：</span>
                <div>
                  <p>19926633862</p>
                  <p>0755-86336555</p>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 第二屏 -->
    <div class="home-two">
      <!-- 介绍 -->
      <div class="home-two-intro">
        <div class="home-two-intro-content img1">
          <h1>数据全私有</h1>
          <p>
            将longmap部署在自有服务器，保证所有数据资料的私密性。支持分布式高可用架构
          </p>
        </div>
        <div class="home-two-intro-content img2">
          <h1>任选平台部署</h1>
          <p>
            根据用户单位需求定制私有化部署方案，任意选择服务器或第三方云平台
          </p>
        </div>
        <div class="home-two-intro-content img3">
          <h1>专属管理后台</h1>
          <p>
            管理整个用户单位内的多团队，企业成员及项目，实现与企业业务系统的顺畅对接和数据共享
          </p>
        </div>
        <div class="home-two-intro-content img4">
          <h1>专业技术支持</h1>
          <p>专属团队及时响应，解决用户的各种问题。</p>
        </div>
        <div class="home-two-intro-content img5">
          <h1>持续升级</h1>
          <p>服务期内将免费提供软件升级服务。</p>
        </div>
        <div class="home-two-intro-content img6">
          <h1>服务器配置建议</h1>
          <p>
            提供服务器配置建议，指导服务器采购——如阿里云、腾讯云或者本地服务器。
          </p>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
export default {
  name: 'Home',
  components: {
    Footer
  },
  data() {
    return {
      // 需求表单
      form: {
        mobile: '',
        name: '',
        demand: ''
      },
      //需求表单验证
      formRules: {
        mobile: [
          { required: true, message: '请输入您的手机号码', trigger: 'blur' },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '请输入正确的号码格式',
            trigger: 'blur'
          }
        ],
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
          {
            pattern: /[\u4E00-\u9FA5\uF900-\uFA2D\-A-Z\-a-z]{2,20}/,
            message: '请输入正确的名称格式',
            trigger: 'blur'
          }
        ],
        demand: [
          { required: true, message: '请简单描述您的需求', trigger: 'blur' },
          { max: 1000, message: '长度最大1000 个字符', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$message.success('提交成功');
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.home {
  height: 100%;
  //第一个屏
  &-one {
    display: flex;
    justify-content: space-between;
    height: 808px;
    background-image: url(~a/home-one-bg.png);
    background-size: contain;
    overflow: hidden;
    // 左侧
    &-left {
      width: 600px;
      margin-left: 260px;
      // 介绍
      &-intro {
        margin-top: 280px;
        margin-bottom: 80px;
        // 标题
        &-title {
          margin-bottom: 68px;
          color: #000000;
          font-weight: 800;
          font-size: 40px;
        }
        // 内容
        &-content {
          font-size: 24px;
          color: #000000;
          line-height: 40px;
        }
      }
      // 按钮
      &-button {
        display: inline-block;
        height: 57px;
        padding: 0 10px;
        background: #0096ff;
        border-radius: 8px;
        text-align: center;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 57px;
      }
    }
    //右侧
    /deep/&-right {
      background-size: contain;
      position: absolute;
      top: 190px;
      right: 260px;
      width: 580px;
      height: 484px;
      background-image: url('../assets/bk-demandForm.png');
      //输入框
      .el-input {
        height: 68px !important;
      }
      .el-form-item__error {
        margin-left: 40px;
      }
      .el-input__inner {
        font-size: 18px;
        margin-left: 40px;
        width: 500px;
        height: 68px !important;
        border-radius: 4px;
      }
      .el-textarea__inner {
        padding: 12px 14px;
        margin-left: 40px;
        height: 136px;
        font-size: 18px;
        width: 500px;
        resize: none;
      }
      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;
      }
      //按钮
      &-btn {
        padding: 0;
        width: 198px;
        height: 57px;
        background: #0096ff;
        border-radius: 8px;
        color: #ffffff;
        font-size: 26px;
        p {
          margin-top: 3px;
          font-size: 14px;
          opacity: 0.9;
        }
      }
      &-text {
        font-size: 20px;
        color: #333333;
        display: flex;
        align-items: center;
        p {
          line-height: 30px;
          font-size: 18px;
        }
      }
    }
  }
  //第二个屏
  &-two {
    background-size: contain;
    overflow: hidden;
    //介绍
    &-intro {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 86px 260px;
      &-content {
        width: 586px;
        height: 206px;
        margin-bottom: 100px;
        background-size: contain;
        // 标题
        h1 {
          margin-left: 22px;
          margin-top: 30px;
          height: 20px;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        // 文字
        p {
          margin-left: 22px;
          margin-top: 20px;
          width: 235px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 28px;
        }
      }
      //背景图片
      .img1 {
        background-image: url('../assets/img-sjymx.png');
      }
      .img2 {
        background-image: url('../assets/img-cnbbs.png');
      }
      .img3 {
        background-image: url('../assets/img-qyjglht.png');
      }
      .img4 {
        background-image: url('../assets/img-zyjszc.png');
      }
      .img5 {
        background-image: url('../assets/img-cxsj.png');
      }
      .img6 {
        background-image: url('../assets/img-fwqpzjy.png');
      }
    }
  }
}
</style>
