<template>
  <div>
    <!-- banner -->
    <div class="banner n1">
      <div class="banner-text w1200">
        <p>智慧国土</p>
        <p class="disription">
          自然资源三维立体“一张图” <span class="line">|</span>实景三维空间云平台
        </p>
        <!-- <a href="javascript:">联系我们</a> -->
      </div>
      <div class="banner-mask"></div>
    </div>
    <!-- 实景三维 -->
    <div class="sjsw bigbt w1200">
      <div class="title">
        <p>实景三维</p>
        <div class="hr"></div>
      </div>
      <div class="sjsw-discription title-top">
        <p>
          神州龙空间技术（深圳）有限公司充分利用基础测绘成果，以三维测绘成果为基底，以遥感影像为背景，集成整合地下空间、地表基质、地表覆盖、业务管理等多源数据，按照统一的标准，构建自然资源三维立体“一张图”；以监测评价、监管决策、政务服务应用体系构建实景三维空间云平台。
        </p>
        <p>
          实景三维是以人眼看到的现实世界的实际景观为表达方式，通过多样化的获取及生产手段，生产制作的具体真实纹理、可量测的实体化三维场景。
        </p>
      </div>
      <div class="sjsw-content">
        <div class="content-item">
          <div class="item-main">
            <div class="item-img">
              <img src="./images/map-sanwei.png" alt="" />
              <div class="item-mask"></div>
            </div>
            <div class="item-title">
              <p>地形级三维</p>
            </div>
            <div class="item-content">
              地形级实景三维场景主要在高空视角展现山川河流等地形地貌以及城市、村镇的分布与形态。
            </div>
          </div>
        </div>
        <div class="content-item">
          <div class="item-main">
            <div class="item-img">
              <img src="./images/city-sanwei.png" alt="" />
              <div class="item-mask"></div>
            </div>
            <div class="item-title">
              <p>城市级三维</p>
            </div>
            <div class="item-content">
              城市级实景三维场景主要表现一定区域的城市风貌、地物，从低空视角观看，能够直观展现居民地、工矿建（构）筑物、交通设施、水系、植被等。
            </div>
          </div>
        </div>
        <div class="content-item">
          <div class="item-main">
            <div class="item-img">
              <img src="./images/bujianjisanwei.png" alt="" />
              <div class="item-mask"></div>
            </div>
            <div class="item-title">
              <p>部件级三维</p>
            </div>
            <div class="item-content">
              部件级实景三维场景主要是微观层面上（包括街景视角、室内视角、地下视角等）表达各类地理实体，包括路灯、井盖、管网等。
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 数据资源体系 -->
    <div class="sjzy bigbt w1200">
      <div class="title">
        <p>数据资源体系</p>
        <div class="hr"></div>
      </div>
      <div class="sjzy-discription title-top">
        <p>
          以资源目录的方式建设自然资源三维立体“一张图”数据资源体系，共包括现状数据、规划管控数据、管理数据和社会经济数据4大类
        </p>
      </div>
      <div class="sjzy-img">
        <img src="./images/shujutixi.png" alt="" />
      </div>
    </div>

    <!-- 云平台建设 -->
    <div class="cloud bigbt w1200">
      <div class="title">
        <p>云平台建设</p>
        <div class="hr"></div>
      </div>
      <div class="cloud-discription title-top">
        <p>实景三维空间云平台</p>
      </div>
      <div class="cloud-main">
        <div class="cloud-img">
          <div class="cloud-ys">
            <div class="ys-text">
              <div class="text-title">要素全管控</div>
              <div class="content">
                <p>矢量数据 栅格数据</p>
                <p>三维数据 时空数据</p>
              </div>
            </div>
          </div>
          <div class="cloud-ys">
            <div class="ys-text">
              <div class="text-title">分析全方位</div>
              <div class="content">
                <p>对比分析 缓冲区分析</p>
                <p>空间叠加分析 天际线分析</p>
              </div>
            </div>
          </div>
          <div class="cloud-ys">
            <div class="ys-text">
              <div class="text-title">要素全管控</div>
              <div class="content">
                <p>地上地下 室内室外</p>
                <p>动态渲染</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 支撑引擎 -->
    <div class="zcyq bigbt w1200">
      <div class="title">
        <p>支撑引擎</p>
      </div>
      <div class="zcyq-img title-top">
        <img src="./images/zhichengtixi.png" alt="" />
      </div>
      <div class="zcyq-content">
        <p>
          数据可视化系统为整个空间平台对外输出可视化能力，提供城市全空间三维可视化、动态数据可视化、仿真数据可视化
          、分析结果可视化、 虚拟现实可视化、轻量化大数据可视化等功能
        </p>
        <p>
          数据分析系统为用户构建深度关联查询、通用空间分析、专题空间分析、场景特效模拟、城市运行指标空间分析、视频融合分析等空间分析工具，帮助用户从空间、时间、属性等多个维度了解和认知时空大数据，为不同颗粒度的数据提供强大深入的分析性能，挖掘更多信息的价值
        </p>
        <p>
          数据管理系统为用户构建数据接入、数据质检、数据发布、数据共享、格式转换、坐标转换、数据转换等服务或自动化工具
        </p>
        <p>
          自然资源调查监测评价、自然资源监管决策 、“互联网+自然资源政务服务”
        </p>
      </div>
    </div>

    <div class="gank"></div>
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
import './../solution.css';
import Footer from '@/components/Footer.vue';
export default {
  name: 'Zhsw',
  components: {
    Footer
  }
};
</script>

<style lang="less" scoped>
/********************* banner start **************/

.banner {
  background-image: url('./images/banner.png');
  background-size: contain;
  /* background-color: red; */
  position: relative;
}
.banner > .banner-text {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -30%);
  z-index: 100;
}
.banner > .banner-text > p:first-child {
  font-size: 50px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 55px;
  letter-spacing: 10px;
}
.banner > .banner-text > p.disription {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
}
.banner > .banner-text > p.disription > .line {
  margin: 0 10px 0 9px;
}
.banner > .banner-text > a {
  width: 247px;
  height: 65px;
  border: 2px solid #ffffff;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 65px;
  text-align: center;
  display: block;
  margin-top: 80px;
  border-radius: 6px;
}

.banner > .wrap > header > .secMune > .item.jjfa {
  left: 594px;
}

/********************* banner end **************/

/********************* 实景三维 start **************/

.sjsw > .sjsw-discription {
  padding: 0 68px;
}
.sjsw > .sjsw-discription > p {
  text-indent: 32px;
  font-size: 16px;
  line-height: 30px;
  font-family: Source Han Sans CN;
  /* font-weight: bold; */
  color: #333;
}
.sjsw > .sjsw-content {
  display: flex;
  justify-content: space-between;
}
.sjsw > .sjsw-content > .content-item {
  width: 387px;
  height: 467px;
  box-shadow: 0px 3px 8px 0px rgb(182 182 182 / 20%),
    0px -3px 7px 0px rgb(182 182 182 / 20%);
}
.sjsw > .sjsw-content > .content-item > .item-main {
  width: 387px;
  height: 416px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.sjsw > .sjsw-content > .content-item > .item-main > .item-img {
  width: 100%;
  height: 234px;
  position: relative;
}
.sjsw > .sjsw-content > .content-item > .item-main > .item-img > .item-mask {
  width: 100%;
  height: 234px;
  position: absolute;
  top: 0;
  background: #3065ee;
  opacity: 0.1;
  border-radius: 10px 10px 0px 0px;
}
.sjsw > .sjsw-content > .content-item > .item-main.item-img > img {
  width: 100%;
  height: 100%;
}
.sjsw > .sjsw-content > .content-item > .item-main > .item-title {
  width: 100%;
  height: 27px;
  line-height: 27px;
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  /* margin:32px 0 20px 0; */
  text-align: center;
}
.sjsw > .sjsw-content > .content-item > .item-main > .item-content {
  width: 336px;
  /* padding:; */
  height: 81px;
  line-height: 28px;
  font-size: 18px;
  font-family: Source Han Sans CN;
  /* font-weight: 400; */
  color: #333;
}

/********************* 实景三维 end **************/

/********************* 数据资源体系 start **************/

.sjzy > .sjzy-discription {
  padding: 0 68px;
}
.sjzy > .sjzy-discription > p {
  text-indent: 32px;
  font-size: 16px;
  line-height: 30px;
  font-family: Source Han Sans CN;
  /* font-weight: bold; */
  color: #333;
}
.sjzy > .sjzy-img {
  height: 692px;
}
.sjzy > .sjzy-img > img {
  width: 100%;
  height: 100%;
}
/********************* 数据资源体系 end **************/

/********************* 云平台建设 start **************/

.cloud > .cloud-discription > p {
  text-indent: 32px;
  font-size: 28px;
  line-height: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  text-align: center;
}
.cloud > .cloud-main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.cloud > .cloud-main > .cloud-img {
  width: 593px;
  height: 627px;
  background-image: url('./images/sanwei-city.png');
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}
.cloud > .cloud-main > .cloud-img > .cloud-ys {
  width: 290px;
  height: 198px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cloud > .cloud-main > .cloud-img > .cloud-ys:nth-child(1) {
  background-image: url('./images/not-circle-right-bottom.png');

  top: 82px;
  left: -242px;
}
.cloud > .cloud-main > .cloud-img > .cloud-ys:nth-child(2) {
  background-image: url('./images/not-circle-top-right.png');

  top: 380px;
  left: -288px;
}
.cloud > .cloud-main > .cloud-img > .cloud-ys:nth-child(3) {
  background-image: url('./images/not-circle-left-bottom.png');

  top: 182px;
  right: -280px;
}

.cloud > .cloud-main > .cloud-img > .cloud-ys > .ys-text {
  width: 100%;
  height: 108px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  /* top:20px;
    left:-220px; */
}
.cloud > .cloud-main > .cloud-img > .cloud-ys > .ys-text > .text-title {
  /* width: 119px; */
  height: 24px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  /* color:#000; */
}
.cloud > .cloud-main > .cloud-img > .cloud-ys > .ys-text > .content > p {
  /* width: 131px; */
  height: 24px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  /* color:#000; */
}
/********************* 云平台建设 end **************/

/********************* 支撑引擎 start **************/

.zcyq > .zcyq-img {
  height: 450px;
  /* width: 1090px; */
  /* margin: 118px 0 40px 0; */
}
.zcyq > .zcyq-img > img {
  width: 100%;
  height: 100%;
}
.zcyq > .zcyq-content {
  display: flex;
  flex-direction: column;
  margin-top: 116px;
  align-items: center;
}
.zcyq > .zcyq-content > p {
  /* height: 80px; */
  line-height: 30px;
  width: 966px;
  /* margin-left:134px; */
  font-size: 16px;
  font-family: Source Han Sans CN;
  /* font-weight: bold; */
  color: #333;
  position: relative;
  margin-bottom: 30px;
  display: flex;
}
.zcyq > .zcyq-content > p::before {
  content: '';
  position: absolute;
  /* margin-right:36px; */
  left: -36px;
  width: 18px;
  height: 18px;
  background: #366aed;
  border-radius: 9px;
  top: 9px;
}
.zcyq > .zcyq-content > p::after {
  content: '';
  position: absolute;
  top: 14px;
  left: -31px;
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
}
/********************* 支撑引擎 end **************/
</style>
