<template>
  <div class="expansionPackage-card">
    <p class="expansionPackage-card-typeName">{{ data.name }}</p>
    <div class="expansionPackage-card-price">
      <p class="expansionPackage-card-price-count">
        {{ parseInt(data.startPriceFormat) }}
      </p>
      <p>元起购</p>
    </div>
    <Button class="expansionPackage-card-btn" @click="$emit('buy')"
      >立即购买</Button
    >
  </div>
</template>

<script>
import Button from 'c/Button.vue';
export default {
  name: 'ExpansionPackageCard',
  components: {
    Button
  },
  props: {
    data: Object
  }
};
</script>

<style lang="less" scoped>
.expansionPackage-card {
  position: relative;
  width: 369px;
  height: 349px;
  background-image: url(assets/expansionPackageCard-bg.png);
  background-size: contain;
  text-align: center;
  &-typeName {
    line-height: 55px;
    color: #fff;
    font-size: 30px;
  }
  &-price {
    display: flex;
    justify-content: center;
    align-items: baseline;
    height: 100px;
    margin-top: 60px;
    color: #b16307;
    font-size: 30px;
    font-weight: bold;
    &-count {
      color: #ff0000;
      font-size: 60px;
    }
  }
  &-btn {
    transition: all 100ms;
    width: 240px;
    height: 56px;
    margin: 0 auto;
    margin-top: 40px;
    line-height: 56px;
    background: #ff0000;
    border-radius: 28px;
    font-size: 26px;
    font-weight: bold;
    &:hover {
      transform: scale(1.05);
    }
  }
}
</style>
