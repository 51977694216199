<template>
  <div class="packageSelection-card-detail-item">
    <div class="packageSelection-card-detail-item-left">
      <img :src="icon" alt="" />
      <p>{{ name }}</p>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PackageSelectionCardDetailItem',
  props: {
    icon: String,
    name: String
  }
};
</script>

<style lang="less" scoped>
.packageSelection-card-detail-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 179px;
  height: 41px;
  box-sizing: border-box;
  padding: 0 30px 0 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  font-size: 24px;
  &-left {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    img {
      transform: translateY(2px);
    }
  }
}
</style>
