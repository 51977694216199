<template>
  <div class="serviceUpgrade">
    <!-- 套餐选择 -->
    <PackageSelection class="serviceUpgrade-container" @buy="handleBuy" />
    <!-- 项目扩容 -->
    <ExpansionPackage class="serviceUpgrade-container" @buy="handleBuy" />
    <!-- 私有化部署 -->
    <PrivatizationDeployment class="serviceUpgrade-container" />
    <Footer />
    <!-- 支付窗口 -->
    <PackagePlayDialog
      v-if="visible"
      :data="packageData"
      @close="handlePlayDialogClose"
      @openPayResultDialog="handleOpenPayResultDialog"
    />
    <!-- 支付结果窗口 -->
    <PayResultDialog
      v-if="payResultDialogVisible"
      :data="payResultDialogData"
      @close="handleOpenPayResultDialogClose"
    />
  </div>
</template>

<script>
import PayResultDialog from 'c/PayResultDialog.vue';
import PackagePlayDialog from 'c/PackagePlayDialog.vue';
import PackageSelection from './PackageSelection.vue';
import ExpansionPackage from './ExpansionPackage.vue';
import PrivatizationDeployment from './PrivatizationDeployment.vue';
import Footer from 'c/Footer.vue';
export default {
  name: 'ServiceUpgrade',
  components: {
    PayResultDialog,
    PackagePlayDialog,
    PackageSelection,
    ExpansionPackage,
    PrivatizationDeployment,
    Footer
  },
  data() {
    return {
      visible: false,
      payResultDialogVisible: false,
      payResultDialogData: '',
      packageData: ''
    };
  },
  methods: {
    handleOpenPayResultDialogClose() {
      this.payResultDialogVisible = false;
      this.payResultDialogData = '';
    },
    handleOpenPayResultDialog(data) {
      this.payResultDialogData = data;
      this.payResultDialogVisible = true;
    },
    handlePlayDialogClose() {
      this.visible = false;
      this.packageData = '';
    },
    handleBuy(data) {
      this.visible = true;
      this.packageData = data;
    }
  }
};
</script>

<style lang="less" scoped>
.serviceUpgrade {
  padding-top: 70px;
  background: url(./assets/top-bg.png) center top no-repeat,
    url(./assets/bottom-bg.png) center calc(100% - 320px) no-repeat;
  background-size: 100% auto;
  &::after {
    content: ' ';
    background-image: url(./assets/bottom-bg.png);
  }
  &-container {
    width: 1440px;
    margin: 100px auto 0;
  }
}
</style>
