<template>
  <div>
    <!-- banner -->
    <div class="banner n1080">
      <div class="banner-text w1200">
        <p>智慧交通大数据平台</p>
        <p class="disription">
          GIS平台，在交通领域充分运用互联网、空间感知、云计算、移动互联等技术，将交通管理、交通运输、公众出行等交通领域以及交通建设管理的全过程进行管控，以充分保证交通安全。
        </p>
        <!-- <a href="javascript:">联系我们</a> -->
      </div>
      <div class="banner-mask"></div>
    </div>
    <!-- 应用体系 -->

    <div class="yytx bigbt w1200">
      <div class="title">
        <p>应用体系</p>
        <div class="hr"></div>
      </div>
      <div class="yytx-img">
        <img src="./images/yytx.png" alt="" />
      </div>
    </div>

    <!-- 运行监测 -->

    <div class="yxjc bigbt w1400">
      <div class="title yxjc-title">
        <p>运行监测</p>
        <div class="hr"></div>
      </div>
      <div class="content">
        <div class="item">
          <!-- <img src="./images/sw&jk.png" alt=""> -->
          <div class="item-active">
            <div class="active-title">
              <div class="title-txt">三维视频融合监控</div>
              <div class="title-mask"></div>
            </div>
            <div class="active-content">
              通过将监控视频映射融合到三维地图上，填补实时监控数据中所丢失的地理信息，直观的了解视频中人、车、物的运动方向和轨迹，满足跨境追踪需求，实现全方位无死角监控和视频资源的便捷调取
            </div>
          </div>
          <div class="mask"></div>
        </div>

        <div class="item">
          <div class="item-active">
            <div class="active-title">
              <div class="title-txt">视频监控</div>
              <div class="title-mask"></div>
            </div>
            <div class="active-content">
              通过部署激光雷达监控，在大雾、火灾浓烟、雨雪等能见度较低的情况下实时准确地检测各类交通事件，例如：违停、车辆逆行、行人、坠落物、野生动物、交通拥堵等，支持接入GIS,实现数据多元融合及拥堵信息计算
            </div>
          </div>
          <div class="mask"></div>
        </div>

        <div class="item">
          <div class="item-active">
            <div class="active-title">
              <div class="title-txt">智能信号灯控制</div>
              <div class="title-mask"></div>
            </div>
            <div class="active-content">
              通过激光扫描仪对道路进行连续扫描，获得这段道路上实时、动态的车流量点云数据，通过数据处理获得车流量等参数，根据东西向和南北向车流量大小的比较以及短暂车流预测，从而自动调节东西向和南北向信号灯周期,实现数据多元融合及拥堵信息计算
            </div>
          </div>
          <div class="mask"></div>
        </div>
      </div>
      <div class="content">
        <div class="item">
          <div class="item-active">
            <div class="active-title">
              <div class="title-txt">交通事故勘察</div>
              <div class="title-mask"></div>
            </div>
            <div class="active-content">
              运用三位激光扫描仪对事故现场进行三位扫描，现场取证，扫描仪的数据能够生成事故现场的高质量图像和细节示意图，便于后期提取和法庭审理,实现数据多元融合及拥堵信息计算
            </div>
          </div>
          <div class="mask"></div>
        </div>

        <div class="item">
          <div class="item-active">
            <div class="active-title">
              <div class="title-txt">物联网交通感知</div>
              <div class="title-mask"></div>
            </div>
            <div class="active-content">
              将交通运行过程中的感知信息定位到虚拟的时空环境中，实现图上感知、控制、分析,实现数据多元融合及拥堵信息计算
            </div>
          </div>
          <div class="mask"></div>
        </div>

        <div class="item">
          <div class="item-active">
            <div class="active-title">
              <div class="title-txt">交通应急指挥</div>
              <div class="title-mask"></div>
            </div>
            <div class="active-content">
              基于大数据的交通应急指挥,实现数据多元融合及拥堵信息计算
            </div>
          </div>
          <div class="mask"></div>
        </div>
      </div>
    </div>

    <!-- 养护监测 -->
    <div class="yhjc bigbt w1200">
      <div class="title yhjc-title">
        <p>养护检测</p>
        <div class="hr"></div>
      </div>
      <div class="items">
        <div class="item">
          <div class="item-img">
            <img src="./images/zsp.png" alt="" />
          </div>
          <div class="item-title">路边指示牌防盗监测</div>
        </div>

        <div class="item">
          <div class="item-img">
            <img src="./images/hl.png" alt="" />
          </div>
          <div class="item-title">护栏碰撞监测</div>
        </div>

        <div class="item">
          <div class="item-img">
            <img src="./images/dlpz.png" alt="" />
          </div>
          <div class="item-title">道路平整度监测</div>
        </div>

        <div class="item">
          <div class="item-img">
            <img src="./images/za.png" alt="" />
          </div>
          <div class="item-title">道路障碍物监测</div>
        </div>
      </div>

      <div class="items">
        <div class="item">
          <div class="item-img">
            <img src="./images/dl.png" alt="" />
          </div>
          <div class="item-title">道路养护巡检</div>
        </div>

        <div class="item">
          <div class="item-img">
            <img src="./images/dlbp.png" alt="" />
          </div>
          <div class="item-title">道路边坡监测</div>
        </div>

        <div class="item">
          <div class="item-img">
            <img src="./images/lj.png" alt="" />
          </div>
          <div class="item-title">路基沉降监测</div>
        </div>

        <div class="item">
          <div class="item-img">
            <img src="./images/ql.png" alt="" />
          </div>
          <div class="item-title">桥梁动态监测</div>
        </div>
      </div>
    </div>

    <!-- 工程管理 -->
    <div class="gcgl bigbt">
      <div class="title">
        <p>工程管理</p>
        <div class="hr"></div>
      </div>
      <div class="w1400">
        <div class="gcgl-con">
          <div class="item">
            <div class="gcgl-img">
              <img src="./images/dlkj.png" alt="" />
            </div>
            <div class="gcgl-title">基于激光雷达道路改扩建分析</div>
          </div>
          <div class="item">
            <div class="gcgl-img">
              <img src="./images/gcgl.png" alt="" />
            </div>
            <div class="gcgl-title">基于数字空间的道路工程管理</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 出行服务 -->
    <div class="cxfw bigbt w1200">
      <div class="title">
        <p>出行服务</p>
        <div class="hr"></div>
      </div>

      <div class="cxfw-cont">
        <div class="cxfw-left">
          <p>立体交通诱导（高架桥、立交桥、隧道等）</p>
          <p>出行热力图</p>
          <p>实时公交及车厢拥挤度（依据公交刷卡次数）</p>
          <p>AR室内停车导航</p>
          <p>精细实时路况（精细到车道级别）</p>
        </div>
        <div class="cxfw-right">
          <img src="./images/cxfw.png" alt="" />
        </div>
      </div>
    </div>

    <div class="gank"></div>
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
import './../solution.css';
import Footer from '@/components/Footer.vue';
export default {
  name: 'Zhsw',
  components: {
    Footer
  }
};
</script>

<style lang="less" scoped>
.zhjt > .bigbt {
  padding-bottom: 0;
}
/********************* banner start **************/
.banner {
  background-image: url('./images/banner.png');
  background-size: contain;
  /* background-color: red; */
  position: relative;
}
.banner > .banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-95%, -46%);
  z-index: 3;
  width: 630px;
}
.banner > .banner-text > p:first-child {
  font-size: 50px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 30px;
  letter-spacing: 4px;
}

.banner > .banner-text > p.disription {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
}

/********************* banner end **************/

/********************* 应用体系 start **************/

.yytx > .yytx-img {
  margin: 81px auto 115px auto;
  height: 590px;
  width: 1200px;
}
.yytx > .yytx-img > img {
  width: 100%;
  height: 100%;
}
/********************* 应用体系 end **************/

/********************* 运行监测 start **************/

.yxjc > .content {
  display: flex;
  margin: 116px 0 0;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 35px;
}
.yxjc > .content:last-child {
  margin: 0 auto;
}
.yxjc > .content > .item {
  width: 446px;
  height: 244px;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 30px;
}
.yxjc > .content > .item:nth-child(1) {
  background-image: url('./images/sw&jk.png');
  /* opacity: .8; */
}
.yxjc > .content > .item:nth-child(2) {
  background-image: url('./images/spjk.png');
  /* opacity: .8; */
}
.yxjc > .content > .item:nth-child(3) {
  margin-right: 0px;
  background-image: url('./images/znd.png');
  /* opacity: .8; */
}
.yxjc > .content:last-child > .item:nth-child(1) {
  background-image: url('./images/jtsg.png');
  /* opacity: .8; */
}
.yxjc > .content:last-child > .item:nth-child(2) {
  background-image: url('./images/wlw.png');
  /* opacity: .8; */
}
.yxjc > .content:last-child > .item:nth-child(3) {
  margin-right: 0px;
  background-image: url('./images/jtyj-copy.png');
  /* opacity: .8; */
}
.yxjc > .content > .item > .item-active {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 188px;
  transition: all 1s ease;
  z-index: 9;
}
.yxjc > .content > .item > .mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 244px;
  background: #0f1d3d;
  border-radius: 10px;
  opacity: 0.4;
  z-index: 10;
  transition: all 0.8s ease;
  /* display: none; */
}
/* .yxjc>.content>.item>.mask.active{
    display: block;
} */
.yxjc > .content > .item > .item-active.active {
  transform: translateY(-158px);
  z-index: 99;
}
.yxjc > .content > .item > .mask.active {
  transform: translateY(-244px);
  z-index: 10;
}
.yxjc > .content > .item > .item-active > .active-title {
  width: 304px;
  height: 50px;
  left: 50%;
  position: absolute;
  transform: translateX(-152px);
}
.yxjc > .content > .item > .item-active > .active-title > .title-txt {
  position: absolute;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  position: relative;
  z-index: 3;
  top: 10px;
}
.yxjc > .content > .item > .item-active > .active-title > .title-mask {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 50%;
  transform: translateX(-152px);
  line-height: 50px;
  background: #333333;
  opacity: 0.2;
  top: 0;
}
.yxjc > .content > .item > .item-active > .active-title > .title-mask.active {
  display: none;
}
.yxjc > .content > .item > .item-active > .active-content {
  position: absolute;
  top: 254px;
  margin: 0 20px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  line-height: 30px;
  /* color:#000; */
  color: #fff;
}

/********************* 运行监测 end **************/

/********************* 养护检测 start **************/

.yhjc > .items {
  margin: 120px 0 32px;
  display: flex;
  justify-content: space-between;
}
.yhjc > .items:last-child {
  margin: 0 0 0;
}
.yhjc > .items > .item {
  width: 218px;
  height: 300px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(182, 182, 182, 0.2),
    0px -3px 7px 0px rgba(182, 182, 182, 0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  align-items: center;
  padding: 48px 30px 0;
}
.yhjc > .items > .item > .item-img {
  width: 100px;
  height: 100px;
  margin-bottom: 65px;
}
.yhjc > .items > .item > .item-img > img {
  width: 100%;
  height: 100%;
}
.yhjc > .items > .item > .item-title {
  /* width: 215px; */
  height: 24px;
  line-height: 24px;
  font-size: 24px;
  font-weight: 400;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
}
/********************* 养护检测 end **************/

/********************* 工程管理 start **************/
.gcgl {
  /* height: 821px; */
  background: #f7f7f7;
  border-top: 1px solid transparent;
  margin-top: 100px;
  /* background:red; */
}
.gcgl.bigbt {
  padding-bottom: 0;
}
.gcgl > .w1400 > .gcgl-con {
  margin: 119px 0 0;
  padding-bottom: 70px;
  display: flex;
  justify-content: space-between;
}
.gcgl > .w1400 > .gcgl-con > .item {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
}
.gcgl > .w1400 > .gcgl-con > .item > .gcgl-img {
  width: 650px;
  height: 420px;
}
.gcgl > .w1400 > .gcgl-con > .item > .gcgl-img > img {
  width: 100%;
  height: 100%;
}
.gcgl > .w1400 > .gcgl-con > .item > .gcgl-title {
  width: 341px;
  height: 26px;
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  margin-top: 30px;
}
/********************* 工程管理 end **************/

/********************* 出行服务 start **************/

.cxfw > .cxfw-cont {
  margin-top: 120px;
  display: flex;
  justify-content: space-between;
}
.cxfw > .cxfw-cont > .cxfw-left > p {
  /* width: 439px; */
  height: 24px;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  margin-bottom: 29px;
}
/********************* 出行服务 end **************/
</style>
