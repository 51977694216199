<template>
  <div class="privatizationDeployment">
    <ServiceUpgradeTitle
      title="私有化部署"
      bgTitle="PRIVATIZATION DEPLOYMENT"
    />
    <p class="privatizationDeployment-title">longmap私有化部署</p>
    <div class="privatizationDeployment-intro">
      数据私密性 . 纯内网部署 . 专属管理后台 . 专业技术支持
    </div>
  </div>
</template>

<script>
import ServiceUpgradeTitle from './ServiceUpgradeTitle.vue';
export default {
  name: 'PrivatizationDeployment',
  components: {
    ServiceUpgradeTitle
  }
};
</script>

<style lang="less" scoped>
.privatizationDeployment {
  text-align: center;
  padding-bottom: 40px;
  &-title {
    margin-top: 100px;
    margin-bottom: 50px;
    color: #333333;
    font-size: 44px;
    font-weight: bold;
  }
  &-intro {
    width: 598px;
    height: 57px;
    margin: 0 auto;
    line-height: 57px;
    border: 1px solid #333333;
    border-radius: 8px;
    color: #333;
    font-size: 20px;
  }
}
</style>
