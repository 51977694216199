<template>
  <div>
    <!-- banner -->
    <div class="banner n1080">
      <div class="banner-text">
        <p>智慧水务一体化解决方案</p>
        <p class="disription">
          基于神州龙自主研发的数字孪生平台（longmap），以实景三维地图为空间载体
          整合云计算、物联网、大数据技术，全面提升水务管理精 细化、智能化水平。
        </p>
        <!-- <a href="javascript:">联系我们</a> -->
      </div>
      <div class="banner-right">
        <!-- <div class="hpxj">
                <img src="./images/飞机 00_00_00-00_00_30.gif" alt="">
            </div>
            <div class="hpxj-title">航拍巡检</div> -->
      </div>
      <div class="banner-mask"></div>
    </div>
    <!-- 特色功能 -->
    <div class="tsgn bigbt w1200">
      <div class="title">
        <p>特色功能</p>
        <div class="hr"></div>
      </div>
      <div class="groups">
        <div class="item">
          <div class="item-main">
            <div class="item-img">
              <img src="./images/clfx.png" alt="" />
            </div>
            <div class="item-title">三维测量分析</div>
          </div>
        </div>
        <div class="item">
          <div class="item-main">
            <div class="item-img">
              <img src="./images/wscl-1.png" alt="" />
            </div>
            <div class="item-title">污水处理工艺三维仿真</div>
          </div>
        </div>
        <div class="item">
          <div class="item-main">
            <div class="item-img">
              <img src="./images/wscl.png" alt="" />
            </div>
            <div class="item-title">管道三维可视化</div>
          </div>
        </div>
        <div class="item">
          <div class="item-main">
            <div class="item-img">
              <img src="./images/wlsj.png" alt="" />
            </div>
            <div class="item-title">物联数据融合</div>
          </div>
        </div>
      </div>
      <div class="groups">
        <div class="item">
          <div class="item-main">
            <div class="item-img">
              <img src="./images/sprh.png" alt="" />
            </div>
            <div class="item-title">视频融合</div>
          </div>
        </div>
        <div class="item">
          <div class="item-main">
            <div class="item-img">
              <img src="./images/znyj.png" alt="" />
            </div>
            <div class="item-title">智能预警</div>
          </div>
        </div>
        <div class="item">
          <div class="item-main">
            <div class="item-img">
              <img src="./images/mnty.png" alt="" />
            </div>
            <div class="item-title">模拟推演</div>
          </div>
        </div>
        <div class="item">
          <div class="item-main">
            <div class="item-img">
              <img src="./images/tes-fj.png" alt="" />
            </div>
            <div class="item-title">无人机巡检</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="yycj bigbt">
      <div class="title">
        <p>应用场景</p>
        <div class="hr"></div>
      </div>
      <div class="groups">
        <div class="item">
          <div class="item-main">
            <div class="item-img">
              <img src="./images/swzh.png" alt="" />
            </div>
            <div class="item-title">水务综合应用</div>
          </div>
        </div>
        <div class="item">
          <div class="item-main">
            <div class="item-img">
              <img src="./images/fzyy.png" alt="" />
            </div>
            <div class="item-title">仿真三维应用</div>
          </div>
        </div>
        <div class="item">
          <div class="item-main">
            <div class="item-img">
              <img src="./images/wren.png" alt="" />
            </div>
            <div class="item-title">无人视频巡检执法应用</div>
          </div>
        </div>
      </div>
      <div class="groups">
        <div class="item">
          <div class="item-main">
            <div class="item-img">
              <img src="./images/swjc.png" alt="" />
            </div>
            <div class="item-title">水环境监测应用</div>
          </div>
        </div>
        <div class="item">
          <div class="item-main">
            <div class="item-img">
              <img src="./images/gcgl.png" alt="" />
            </div>
            <div class="item-title">工程管理应用</div>
          </div>
        </div>
        <div class="item">
          <div class="item-main">
            <div class="item-img">
              <img src="./images/mnyl.png" alt="" />
            </div>
            <div class="item-title">模拟演练与灾害预警系统</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 经典案例 -->
    <div class="zhsw jdal bigbt w1200">
      <div class="title">
        <p>经典案例</p>
        <div class="hr"></div>
      </div>
      <div class="groups">
        <div class="item">
          <div class="img">
            <img src="./images/jd01.png" alt="" />
          </div>
          <div class="tit">真景三维河道采集与建模项目</div>
        </div>
        <div class="item">
          <div class="img">
            <img src="./images/jd02.png" alt="" />
          </div>
          <div class="tit">河涌真景三维成像项目</div>
        </div>
        <div class="item">
          <div class="img">
            <img src="./images/jd03.png" alt="" />
          </div>
          <div class="tit">真景三维河道管理系统</div>
        </div>
        <div class="item">
          <div class="img">
            <img src="./images/jd04.png" alt="" />
          </div>
          <div class="tit">无人机巡检河道项目</div>
        </div>
      </div>
    </div>
    <div class="gank"></div>
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
import './../solution.css';
import Footer from '@/components/Footer.vue';
export default {
  name: 'Zhsw',
  components: {
    Footer
  }
};
</script>

<style lang="less" scoped>
/********************* banner start **************/

.banner {
  background-image: url('./images/banner.png');
  background-size: contain;
  /* background-color: red; */
  position: relative;
}
.banner > .banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 622px;
  transform: translate(-597px, -50%);
  z-index: 3;
}
.banner > .banner-text > p:first-child {
  font-size: 50px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 56px;
  letter-spacing: 4px;
}

.banner > .banner-text > p.disription {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 40px;
}

.banner > .banner-text > a {
  width: 247px;
  height: 65px;
  border: 2px solid #ffffff;
  border-radius: 4px;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 65px;
  text-align: center;
  display: block;
  margin-top: 80px;
}

.banner > .wrap > header > .secMune > .item.jjfa {
  left: 592px;
}
/* .banner-right{
    position: absolute;
    width: 750px;
    height: 650px;
    background-image:url('../images/pozition.png');
    background-repeat: no-repeat;
    background-size: cover;
    top:105px;
    right:0;
    z-index:999;
}
.banner-right>.hpxj{
    width: 263px;
    height: 135px;
    background-color: #6CD9D5;
    opacity:0.3;
    position:absolute;
    top:120px;
    right:300px;
}
.banner-right>.hpxj>img{
    width: 263px;
    height: 135px;
}
.banner-right>.hpxj-title{
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
    position:absolute;
    top:220px;
    right:400px;
} */
/********************* banner end **************/
/********************* 特色功能 start **************/
.tsgn > .tsgn-title {
  width: 166px;
  height: 40px;
  font-size: 42px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
}
.tsgn > .groups {
  display: flex;
  height: 300px;
  justify-content: space-around;
}
.tsgn > .groups:nth-child(2) {
  margin: 120px 0 30px 0;
}
.tsgn > .groups > .item {
  width: 18%;
  height: 80%;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(182, 182, 182, 0.2),
    0px -3px 7px 0px rgba(182, 182, 182, 0.2);
  border-radius: 8px;
  padding: 20px;
}
.tsgn > .groups > .item > .item-main {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 76%;
}
.tsgn > .groups > .item > .item-main > .item-img {
  width: 100px;
  height: 100px;
}
.tsgn > .groups > .item > .item-main > .item-img > img {
  width: 100%;
  height: 100%;
}
.tsgn > .groups > .item > .item-main > .item-title {
  /* width: 96px; */
  width: 120%;
  transform: scale(0.8);
  height: 30px;
  line-height: 30px;
  font-size: 24px;
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
}
/********************* 特色功能 end **************/

/********************* 应用场景 start **************/

.yycj > .groups {
  padding: 0 82px;
  display: flex;
  /* height:339px; */
  justify-content: space-around;
  align-items: center;
}

.yycj > .groups > .item > .item-main {
  /* width: 100%;
    height:100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.yycj > .groups > .item > .item-main > .item-img {
  width: 556px;
  height: 339px;
  width: 100%;
  height: 80%;
  /* background: #333333; */
  opacity: 0.8;
  border-radius: 10px;
}

.yycj > .groups > .item > .item-main > .item-img > img {
  width: 100%;
  height: 100%;
  z-index: 999;
}
.yycj > .groups > .item > .item-main > .item-title {
  /* width: 222px; */
  height: 28px;
  line-height: 28px;
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333;
  z-index: 9;
  margin: 32px 0 60px 0;
}
.yycj > .groups:nth-child(2) {
  margin: 120px 0 30px 0;
}

/********************* 应用场景 end **************/

/********************* 经典案例 start **************/
.zhsw.jdal,
.zhsw.bigbt {
  padding: 0;
}
.jdal > .groups {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 270px;
}
.jdal > .groups > .item {
  flex: 1;
  width: 307px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.jdal > .groups > .item > .tit {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  width: 100%;
  text-align: center;
  margin-top: 40px;
}
.jdal > .groups > .item:nth-child(1) > .img {
  width: 222px;
  height: 186px;
}
.jdal > .groups > .item:nth-child(2) > .img {
  width: 222px;
  height: 186px;
}
.jdal > .groups > .item:nth-child(3) > .img {
  width: 222px;
  height: 186px;
}
.jdal > .groups > .item:nth-child(4) > .img {
  width: 176px;
  margin-right: 0;
  height: 176px;
}
.jdal > .groups > .item > .img > img {
  width: 100%;
}
/********************* 经典案例 end **************/
</style>
