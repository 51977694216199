<template>
  <div class="bk">
    <div class="footer">
      <!-- 底部左侧 -->
      <div class="footer-left">
        <div>
          <img
            src="@/assets/icon-company.png"
            alt="公司"
            class="footer-left-img"
          />
          <span class="footer-left-text">神州龙空间技术（深圳）有限公司</span>
          <div>
            <img
              src="@/assets/icon-address.png"
              alt="地址"
              class="footer-left-img"
            />
            <span class="footer-left-text"
              >地址：深圳市南山科技中二路深圳软件园西区13栋6楼东</span
            >
          </div>
          <div>
            <img
              src="@/assets/icon-phone2.png"
              alt="电话"
              class="footer-left-img"
            />
            <span class="footer-left-text"
              >联系电话：0755-8633-6555/19926633862</span
            >
          </div>
        </div>
      </div>
      <!-- 底部右侧 -->
      <div class="footer-right">
        <div>
          <div class="footer-right-img" />
          <div class="footer-right-text">扫码关注公众号</div>
        </div>
      </div>
    </div>
    <div class="footer-ICP">
      粤ICP备16127842号 神州龙空间技术（深圳）有限公司版权所有
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
.bk {
  display: flex;
  width: 100%;
  height: 320px;
  background: #252d45;
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%);
    width: 1400px;
    height: 277px;
    border-bottom: 1px solid rgba(229, 229, 229, 0.5);
    &-left {
      display: flex;
      align-items: center;
      &-img {
        width: 15px;
        height: 15px;
      }
      &-text {
        margin-left: 10px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        opacity: 0.6;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      &-img {
        background-size: contain;
        width: 105px;
        height: 105px;
        background-image: url('../assets/icon-GZH.png');
      }
      &-text {
        height: 14px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
      }
    }
  }
  .footer-ICP {
    margin: 277px auto 0;
    height: 17px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    opacity: 0.6;
  }
}
</style>
