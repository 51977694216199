<template>
  <div class="packageSelection">
    <ServiceUpgradeTitle
      title="套餐选择"
      bgTitle="PACKAGE SELECTION"
      subTitle="可以根据您团队规模与业务需求，选择相应套餐"
    />
    <div class="packageSelection-container" v-if="packages.length">
      <PackageSelectionCard
        class="packageSelection-card"
        v-for="item in packages"
        :key="item.id"
        :type="item.packageType"
        :data="item"
        @buy="$emit('buy', item)"
      />
    </div>
  </div>
</template>

<script>
import PackageSelectionCard from './PackageSelectionCard.vue';
import ServiceUpgradeTitle from './ServiceUpgradeTitle.vue';
export default {
  name: 'PackageSelection',
  components: {
    PackageSelectionCard,
    ServiceUpgradeTitle
  },
  data() {
    return {
      packages: []
    };
  },
  created() {
    // 获取套餐信息
    this.$service.quota.packageList({ type: [1] }).then(res => {
      const { status, data } = res;
      if (status === 200) {
        this.packages = data.map(item => {
          const { name } = item;
          if (name.indexOf('体验套餐') !== -1) {
            item.packageType = 'experience';
            item.subTitle = 'Experience package';
          }
          if (name.indexOf('进阶套餐') !== -1) {
            item.packageType = 'advanced';
            item.subTitle = 'Advanced package';
          }
          return item;
        });
      }
    });
  }
};
</script>

<style lang="less" scoped>
.packageSelection {
  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 60px;
  }

  &-card {
    margin-bottom: 70px;
  }
}
</style>
