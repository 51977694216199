<template>
  <div>
    <!-- banner -->
    <div class="banner zhwl">
      <div class="banner-text">
        <p>看见真实的风景</p>
        <p class="disription">Longmap三维智慧旅游解决方案</p>
        <!-- <a href="javascript:">联系我们</a> -->
        <!-- <div class="hr"></div> -->
      </div>
      <div class="banner-mask"></div>
    </div>
    <!-- 720全景 -->
    <!-- <div class="qj720">
      <div class="panorama-container">
        <div id="panorama"></div>
        <div class="content">
          <p>VR全景</p>
          <p>足不出户 游览名胜</p>
        </div>
        <a href="" target="_blank" class="panorama-link">立即浏览</a>
        <div class="panorama-dot">
          <div
            class="panorama-dot-item active"
            data-panoramaUrl="http://wh.longmap.com/data/panorama/szwgy/cut1/"
            data-href="http://wh.longmap.com/pages/panorama-image.html?id=63&title=%E6%B7%B1%E5%9C%B3%E6%B9%BE%E5%85%AC%E5%9B%AD&outId=7161f592000000007c4225110000002f"
          ></div>
          <div
            class="panorama-dot-item"
            data-panoramaUrl="http://wh.longmap.com/data/panorama/szmsg/cut20/"
            data-href="http://wh.longmap.com/pages/panorama-image.html?id=66&title=%E6%B7%B1%E5%9C%B3%E7%BE%8E%E6%9C%AF%E9%A6%86&outId={CB5B2D3A-FFFF-FFFF-E290-CB700000001C}"
          ></div>
          <div
            class="panorama-dot-item"
            data-panoramaUrl="http://wh.longmap.com/data/panorama/dpsc/cut1/"
            data-href="http://wh.longmap.com/pages/panorama-image.html?id=79&title=%E5%A4%A7%E9%B9%8F%E6%89%80%E5%9F%8E&outId={CB5B2D31-FFFF-FFFF-BBD8-4FCD0000000A}"
          ></div>
        </div>
        <div class="prev">
          <img src="./images/prev.png" alt="" />
        </div>
        <div class="next">
          <img src="./images/next.png" alt="" />
        </div>
      </div>
    </div> -->

    <!-- 解决方案 -->
    <div class="jjfa bigbt">
      <div class="title">
        <p>解决方案</p>
        <div class="hr"></div>
      </div>
      <div class="content">
        <div class="groups">
          <div class="item">
            <img src="./images/sw.png" alt="" />
            <p>三维可视化</p>
          </div>

          <div class="item">
            <img src="./images/sj.png" alt="" />
            <!-- <div class="shape">
                    <div class="hr"></div>
                    <div class="point"></div>
                </div> -->
            <p>数据可视化</p>
          </div>

          <div class="item">
            <img src="./images/vr.png" alt="" />
            <!-- <div class="shape">
                    <div class="hr"></div>
                    <div class="point"></div>
                </div> -->
            <p>VR全景景区</p>
          </div>

          <div class="item">
            <img src="./images/AR.png" alt="" />
            <!-- <div class="shape">
                    <div class="hr"></div>
                    <div class="point"></div>
                </div> -->
            <p>AR导览</p>
          </div>
        </div>

        <div class="groups">
          <div class="item">
            <img src="./images/dh.png" alt="" />
            <!-- <div class="shape">
                    <div class="hr"></div>
                    <div class="point"></div>
                </div> -->
            <p>真景三维导航</p>
          </div>

          <div class="item">
            <img src="./images/tp.png" alt="" />
            <!-- <div class="shape">
                    <div class="hr"></div>
                    <div class="point"></div>
                </div> -->
            <p>电子导览图</p>
          </div>

          <div class="item">
            <img src="./images/fp.png" alt="" />
            <!-- <div class="shape">
                    <div class="hr"></div>
                    <div class="point"></div>
                </div> -->
            <p>电子票务</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="yycj bigbt">
      <div class="title">
        <p>应用场景</p>
        <div class="hr"></div>
      </div>
      <div class="content">
        <div class="groups">
          <div class="item">
            <img src="./images/lyjq.png" alt="" />
            <div class="txt-shadow">旅游景区</div>
            <div class="item-mask"></div>
          </div>
          <div class="item">
            <img src="./images/xxdj.png" alt="" />
            <div class="txt-shadow">休闲度假</div>
            <div class="item-mask"></div>
          </div>
          <div class="item">
            <img src="./images/zg.png" alt="" />
            <div class="txt-shadow">展馆</div>
            <div class="item-mask"></div>
          </div>
        </div>

        <div class="groups">
          <div class="item">
            <img src="./images/zfjg.png" alt="" />
            <div class="txt-shadow">政府机关</div>
            <div class="item-mask"></div>
          </div>
          <div class="item">
            <img src="./images/ztly.png" alt="" />
            <div class="txt-shadow">主题乐园</div>
            <div class="item-mask"></div>
          </div>
          <div class="item">
            <img src="./images/hzzx.png" alt="" />
            <div class="txt-shadow">会展活动</div>
            <div class="item-mask"></div>
          </div>
          <div class="item">
            <img src="./images/wyyc.png" alt="" />
            <div class="txt-shadow">文艺演出</div>
            <div class="item-mask"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 经典案例 -->
    <div class="jdal bigbt">
      <div class="title">
        <p>经典案例</p>
        <div class="hr"></div>
      </div>
      <div class="content w1200">
        <div class="item">
          <img src="./images/tmc.png" alt="" />
          <p>文体旅游多维管理平台</p>
        </div>
        <div class="item">
          <img src="./images/zfpt.png" alt="" />
          <p>旅游大数据平台</p>
        </div>
      </div>
    </div>

    <div class="gank"></div>
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
import './../solution.css';
import Footer from '@/components/Footer.vue';
export default {
  name: 'Zhsw',
  components: {
    Footer
  }
};
</script>

<style lang="less" scoped>
html {
  overflow-y: overlay;
}

/********************* banner start **************/

.banner {
  background-image: url('./images/banner-1.png');
  /* background-color: red; */
  height: 1080px;
}
.banner.active {
  z-index: 999;
}
.banner > .banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -56%);
  height: 196px;
  /* width: 647px;
    height: 196px; */
  /* background: rgba(51, 51, 51, 0.1); */
  /* padding-left: 30px; */
  box-sizing: border-box;
  /* padding-top: 25px; */
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.banner > .banner-text > p:first-child {
  font-size: 72px;
  font-weight: 600;
  color: #ffffff;
  letter-spacing: 16px;
  line-height: 100%;
}

.banner > .banner-text > p:nth-child(2) {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  margin-top: 34px;
  line-height: 100%;
  letter-spacing: 3px;
}

.banner > .banner-text > .hr {
  width: 154px;
  height: 4px;
  background: #ffffff;
  border-radius: 2px;
  margin-top: 24px;
}
.banner.zhwl > .wrap > header > .secMune > .item.jjfa {
  left: 644px;
}
.zhwl > .active-wrap > header.active > .secMune-active > .item-active {
  left: 644px;
}
/********************* banner end **************/

/********************* 720全景 start **************/

.qj720 {
  height: 1080px;
  position: relative;
}
.panorama-container {
  position: relative;
  height: 1080px;
}

.qj720 > .panorama-container > .prev,
.qj720 > .panorama-container > .next {
  position: absolute;
  width: 25px;
  height: 46px;
  top: 517px;
  cursor: pointer;
}
.qj720 > .panorama-container > .prev {
  left: 80px;
}
.qj720 > .panorama-container > .next {
  right: 80px;
}
#panorama {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.qj720 > .panorama-container > .content {
  background-image: url('./images/qjbk.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 511px;
  height: 178px;
  /* border: 4px solid #FFFFFF; */
  position: absolute;
  top: 375px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.qj720 > .panorama-container > .content > p {
  text-align: center;
  line-height: 100%;
  letter-spacing: 3px;
}

.qj720 > .panorama-container > .content > p:first-child {
  font-size: 80px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 30px;
}

.qj720 > .panorama-container > .content > p:last-child {
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
}

.panorama-icon {
  width: 28%;
  height: auto;
  margin-top: -50px;
}

.panorama-icon,
.panorama-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.panorama-link {
  cursor: pointer;
  margin-top: 100px;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 24px;
  width: 300px;
  height: 78px;
  border: 2px solid #ffffff;
  border-radius: 39px;
  font-size: 32px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 78px;
}
.panorama-dot {
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  bottom: 56px;
  width: 100px;
  transform: translateX(-50%);
}
.panorama-dot-item {
  cursor: pointer;
  width: 14px;
  height: 14px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  font-size: 0;
}
.panorama-dot-item.active {
  background: #fff;
}
/********************* 720全景 end **************/

/********************* 解决方案 start **************/

.jjfa {
  overflow: hidden;
  padding: 0 82px 0 82px !important;
}

.jjfa > .content {
  margin-top: 86px;
}

.jjfa > .content > .groups {
  display: flex;
  justify-content: center;
  align-items: center;
}
.jjfa > .content > .groups:last-child > .item {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.jjfa > .content > .groups > .item {
  /* background-image: url('../images/jjfabj.png'); */
  width: 275px;
  height: 300px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(182, 182, 182, 0.2),
    0px -3px 7px 0px rgba(182, 182, 182, 0.2);
  border-radius: 8px;
  padding: 48px 10px;
  box-sizing: border-box;
  margin-right: 31px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.jjfa > .content > .groups > .item {
  margin-bottom: 20px;
}

.jjfa > .content > .groups > .item:last-child {
  margin-right: 0;
}

/* 
.jjfa>.content>.item:nth-child(1)>.shape, .jjfa>.content>.item:nth-child(1)>p {
    margin-left: 56px;
}

.jjfa>.content>.item:nth-child(2)>.shape, .jjfa>.content>.item:nth-child(2)>p {
    margin-left: 53px;
}

.jjfa>.content>.item:nth-child(3)>.shape, .jjfa>.content>.item:nth-child(3)>p {
    margin-left: 45px;
}

.jjfa>.content>.item:nth-child(4)>.shape, .jjfa>.content>.item:nth-child(4)>p {
    margin-left: 70px;
}

.jjfa>.content>.item:nth-child(5)>.shape, .jjfa>.content>.item:nth-child(5)>p {
    margin-left: 41px;
}

.jjfa>.content>.item:nth-child(6)>.shape, .jjfa>.content>.item:nth-child(6)>p {
    margin-left: 54px;
}

.jjfa>.content>.item:nth-child(7)>.shape, .jjfa>.content>.item:nth-child(7)>p {
    margin-left: 66px;
} */

.jjfa > .content > .groups > .item > img {
  width: 100px;
  height: 100px;
  /* margin: 0 auto 90px auto; */
  display: block;
}

.jjfa > .content > .groups > .item > .shape {
  display: flex;
  margin-bottom: 28px;
}

.jjfa > .content > .groups > .item > .shape > .hr {
  width: 40px;
  height: 6px;
  background: linear-gradient(88deg, #4e71f4 0%, #6686fe 99%);
  border-radius: 3px;
}

.jjfa > .content > .groups > .item > .shape > .point {
  width: 6px;
  height: 6px;
  background: linear-gradient(88deg, #4d70f4 0%, #6886ff 99%);
  border-radius: 50%;
  margin-left: 5px;
}

.jjfa > .content > .groups > .item > p {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: #333333;
}

/********************* 解决方案 end **************/

/********************* 应用场景 start **************/

.yycj {
  overflow: hidden;
  padding-bottom: 0 !important;
  padding-left: 82px;
  padding-right: 82px;
}

.yycj > .content {
  margin-top: 92px;
}

.yycj > .content > .groups {
  display: flex;
  align-items: center;
  justify-content: center;
}

.yycj > .content > .groups:first-child {
  padding: 0 206px;
}

.yycj > .content > .groups:first-child > .item {
  margin-right: 52px;
}

.yycj > .content > .groups:first-child > .item:last-child {
  margin-right: 0;
}

.yycj > .content > .groups:last-child {
  margin-top: 53px;
}

.yycj > .content > .groups:last-child > .item {
  margin-right: 52px;
}

.yycj > .content > .groups:last-child > .item:last-child {
  margin-right: 0;
}

.yycj > .content > .groups > .item {
  width: 400px;
  /* height: 300px; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
.yycj > .content > .groups > .item > img {
  width: 100%;
  height: 100%;
  transition: all 1s ease;
}
.yycj > .content > .groups > .item:hover > img {
  transform: scale(1.03);
}
/* .yycj>.content>.groups>.item:hover>.txt-shadow>p{
    transform: scale(1.2);
} */
.yycj > .content > .groups > .item > .txt-shadow {
  position: absolute;
  bottom: 38px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 40%;
  height: 34px;
  line-height: 34px;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
  z-index: 3;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  padding: 4px 20px;
}
.yycj > .content > .groups:nth-child(2) > .item > .txt-shadow {
  bottom: 42px;
}
.yycj > .content > .groups > .item > .item-mask {
  position: absolute;
  width: 100%;
  height: 98%;
  background: #3065ee;
  opacity: 0.1;
  border-radius: 8px;
  z-index: -10;
  top: 0;
}

/* .yycj>.content>.groups:first-child>.item:nth-child(1) {
    background-image: url('../images/lyjq.png');
}

.yycj>.content>.groups:first-child>.item:nth-child(2) {
    background-image: url('../images/xxdj.png');
}

.yycj>.content>.groups:first-child>.item:nth-child(3) {
    background-image: url('../images/zg.png');
}

.yycj>.content>.groups:last-child>.item:nth-child(1) {
    background-image: url('../images/zfjg.png');
}

.yycj>.content>.groups:last-child>.item:nth-child(2) {
    background-image: url('../images/ztly.png');
}

.yycj>.content>.groups:last-child>.item:nth-child(3) {
    background-image: url('../images/hzzx.png');
}

.yycj>.content>.groups:last-child>.item:nth-child(4) {
    background-image: url('../images/wyyc.png');
} */

/********************* 应用场景 end **************/

/********************* 经典案例 start **************/

.jdal {
  background-color: #fff !important;
  padding-bottom: 0 !important;
}

/********************* 经典案例 end **************/
</style>
